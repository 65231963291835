//react
import { Dispatch, FC, SetStateAction, useState } from 'react';
//recoil
import { useRecoilValue } from 'recoil';
//atoms
import { calendarFullScreenAtom } from '@atoms/calendarFullScreen';
//theme
import theme from '@src/theme';
//mui
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import {
  BaseSingleInputFieldProps,
  DatePicker,
  DatePickerProps,
  DateValidationError,
  FieldSection,
  UseDateFieldProps
} from '@mui/x-date-pickers';
//icons
import AddIcon from '@mui/icons-material/Add';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MenuIcon from '@mui/icons-material/Menu';
//hooks
import { useTranslation } from 'react-i18next';
import useWindowSize from '@hooks/windowSize/useWindowSize';
//dayjs
import { Dayjs } from 'dayjs';
//mui-locale

//fullcaledar
import { RefObject } from '@fullcalendar/core/preact';
import FullCalendar from '@fullcalendar/react';

interface CalendarHeaderProps {
  addApointment: (() => void) | undefined;
  calendarTitle: string;
  handleChangeDay: (changeType: 'next' | 'prev' | 'nextWeek' | 'prevWeek' | 'today') => void;
  selectedDate: Dayjs | null;
  setSelectedDate: Dispatch<SetStateAction<Dayjs | null>>;
  calendarRef?: RefObject<FullCalendar>;
  setOpenCalendarMobileMenu: Dispatch<SetStateAction<boolean>>;
}
interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CalendarHeaderActions: FC<CalendarHeaderProps> = ({
  addApointment,
  calendarTitle,
  handleChangeDay,
  selectedDate,
  setSelectedDate,
  calendarRef,
  setOpenCalendarMobileMenu
}): JSX.Element => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const calendarFullScreen = useRecoilValue(calendarFullScreenAtom);
  function ButtonField(props: ButtonFieldProps) {
    const {
      setOpen,
      id,
      InputProps: { ref } = {},
      inputProps: { 'aria-label': ariaLabel } = {}
    } = props;

    return (
      <Button
        sx={{ color: '#242A38', ml: { xs: -0.9, sm: 0 } }}
        id={id}
        ref={ref}
        aria-label={ariaLabel}
        onClick={() => setOpen?.(prev => !prev)}
      >
        <CalendarTodayIcon />
      </Button>
    );
  }
  function ButtonDatePicker(props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>) {
    const [open, setOpen] = useState(false);
    const lng = localStorage.getItem('lng');
    return (
      <DatePicker
        slots={{ field: ButtonField, ...props.slots }}
        slotProps={{ field: { setOpen } as any }}
        {...props}
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedDate(null);
        }}
        onOpen={() => setOpen(true)}
      />
    );
  }

  return (
    <Box mt={!calendarFullScreen ? 0 : -2.5}>
      <Grid
        container
        display={'flex'}
        justifyContent={{ xs: 'end', md: 'space-between', lg: 'end' }}
      >
        <Grid
          item
          sm={12}
          md={7}
          lg={!calendarFullScreen ? 6 : 4.5}
          xl={4.5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={{ xs: 'end', sm: 'start' }}
        >
          <Button
            sx={{
              color: theme.palette.primary.main,
              height: '42px',
              mb: 0.3,
              background: '#E0E7FF',
              display: { xs: 'none', sm: 'flex' }
            }}
            onClick={() => handleChangeDay('today')}
          >
            {t('today')}
          </Button>
          <Box display={'flex'} flexDirection={'row'} ml={{ xs: 0, sm: 3 }}>
            <Button
              sx={{
                background: '#E9EAEB',
                height: '42px',
                width: '42px',
                borderRadius: '6px',
                display: { xs: 'none', sm: 'flex' }
              }}
              onClick={() => handleChangeDay('prevWeek')}
            >
              <KeyboardDoubleArrowLeftIcon sx={{ color: 'gray' }} />
            </Button>
            <Box
              display={'flex'}
              flexDirection={'row'}
              borderRadius={'6px'}
              border={{ xs: 'none', sm: '1px solid #E9EAEB' }}
              ml={{ xs: -1, sm: 1.5 }}
              mr={{ xs: -1, sm: 1.5 }}
            >
              {width > 354 && (
                <IconButton
                  sx={{
                    height: '42px',
                    borderRadius: '6px',
                    border: { xs: 'none', sm: '1px solid #E9EAEB' }
                  }}
                  onClick={() => handleChangeDay('prev')}
                >
                  <KeyboardArrowLeftIcon sx={{ color: 'gray' }} />
                </IconButton>
              )}
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                width={{ xs: '170px', sm: '196px' }}
              >
                <ButtonDatePicker
                  label={selectedDate == null ? null : selectedDate.format('MM/DD/YYYY')}
                  value={selectedDate}
                  onChange={newValue => {
                    let date = newValue && new Date(newValue.format('YYYY-MM-DD'));
                    setSelectedDate(newValue);
                    date && calendarRef?.current?.getApi().gotoDate(date);
                  }}
                />
                <Typography variant="body1" color="#242A38">
                  {calendarTitle}
                </Typography>
              </Box>
              {width > 354 && (
                <IconButton
                  sx={{
                    height: '42px',
                    borderRadius: '6px',
                    border: { xs: 'none', sm: '1px solid #E9EAEB' }
                  }}
                  onClick={() => handleChangeDay('next')}
                >
                  <KeyboardArrowRightIcon sx={{ color: 'gray' }} />
                </IconButton>
              )}
            </Box>
            <Button
              sx={{
                background: '#E9EAEB',
                height: '42px',
                width: '42px',
                borderRadius: '6px',
                display: { xs: 'none', sm: 'flex' }
              }}
              onClick={() => handleChangeDay('nextWeek')}
            >
              <KeyboardDoubleArrowRightIcon sx={{ color: 'gray' }} />
            </Button>
          </Box>
          <Button
            sx={{
              color: theme.palette.primary.main,
              height: '42px',
              background: 'none',
              display: { xs: 'flex', sm: 'none' },
              mr: 1
            }}
            onClick={() => handleChangeDay('today')}
          >
            {t('today')}
          </Button>
          <IconButton
            id="MenuIconCalendarMobile"
            data-testid="MenuIconCalendarMobile"
            sx={{
              borderRadius: '6px',
              border: '1px solid #CACCCF',
              color: 'black',
              display: { xs: 'flex', sm: 'none' },
              mr: width < 400 && width > 364 ? -2 : width < 365 ? -3 : 0
            }}
            onClick={() => setOpenCalendarMobileMenu(true)}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          md={4}
          display={{ xs: 'none', lg: 'flex' }}
          justifyContent={'end'}
          alignItems={'center'}
        >
          {addApointment && (
            <Button
              variant="contained"
              sx={{
                fontSize: { md: '14px' }
              }}
              startIcon={<AddIcon />}
              onClick={addApointment}
            >
              {t('calendar.add_appointment')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CalendarHeaderActions;
