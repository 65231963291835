import {
  AnalyticsCountsResponseType,
  AppointmentsCountByHourResponseType,
  CourtCountBySportResponseType,
  LatestAppointmentsResponseType,
  LatestUsersResponseType,
  ObjectAnalyticsCountsTypes,
  ObjectAppointmentsCountType,
  SportCenterAnalyticCountResponseType,
  SportCenterMostActiveUsersResponseType,
  ObjectsMostPopularAnalyticsType,
  ObjectAnalyticsCriteriaType,
  ObjectAnalyticsFilterQueryParamsType,
  ObjectAnalyticsFilterResponseType,
  UsersCountType,
  LatestUsersCountType,
  UsersByGendersType,
  AdminAppointmentsCountType,
  AdminLastAppointmentsAnalyticsType,
  AdminCanceledAppointmentsAnalyticsType,
  AdminObjectUsageAnalyticsResponseType,
  AdminUsersCountType,
  AdminUsersPercentageType,
  AdminUsersFrequencyType,
  SportCenterAppointmentsCountByHourResponseType,
  AdminMostVisitedDaysType
} from '@interfaces/analytics/analytics';
import { MetaType } from '@interfaces/apiResponse';
import axiosInstance from '@services/config';

//superadmin
export const getAnalyticsCounts = (): Promise<{ data: AnalyticsCountsResponseType }> =>
  axiosInstance.get('/analytics/counts');

export const getLatestUsers = (limit?: number): Promise<{ data: LatestUsersResponseType }> =>
  axiosInstance.get('/analytics/latest-users', {
    params: {
      limit: limit
    }
  });

export const getAppointmentsCountByHour = (
  datetime_from?: string,
  datetime_to?: string
): Promise<{ data: AppointmentsCountByHourResponseType }> =>
  axiosInstance.get('/analytics/appointments-count-by-hour', {
    params: {
      datetime_from: datetime_from,
      datetime_to: datetime_to
    }
  });

export const getLatestappointments = (
  limit?: number
): Promise<{ data: LatestAppointmentsResponseType }> =>
  axiosInstance.get('/analytics/latest-appointments', {
    params: {
      limit: limit
    }
  });

export const getCourtsCountBySport = (): Promise<{ data: CourtCountBySportResponseType }> =>
  axiosInstance.get('/analytics/courts-count-by-sport');

// sueradmin object overview
export const getSportCenterAnalyticsCount = (
  sportCenter: number
): Promise<{ data: SportCenterAnalyticCountResponseType }> =>
  axiosInstance.get(`/analytics/by-sport-center/${sportCenter}/appointments`);

export const getSportCenterAppointmentsCountByHour = (
  sportCenter: number,
  params?: {
    frontApp?: 'cms' | 'client' | undefined;
    datetime_from?: string;
    datetime_to?: string;
  }
): Promise<SportCenterAppointmentsCountByHourResponseType> =>
  axiosInstance.get(`/analytics/by-sport-center/${sportCenter}/appointments-count-by-hour`, {
    params
  });

export const getSportCenterMostActiveUsers = (
  sportCenter: number,
  params?: {
    limit?: number;
    with_favorite_sport?: boolean;
    with_channel?: boolean;
  }
): Promise<{ data: SportCenterMostActiveUsersResponseType }> =>
  axiosInstance.get(`/analytics/by-sport-center/${sportCenter}/most-active-users`, {
    params
  });

//admin analytics
export const getObjectAnalyticsCounts = (): Promise<{
  data: { data: ObjectAnalyticsCountsTypes };
}> => axiosInstance.get('/analytics-sport-centers/counts');

export const getObjectAppointmentsCount = (): Promise<{
  data: { data: ObjectAppointmentsCountType };
}> => axiosInstance.get('/analytics-sport-centers/appointments-count-by-day-per-sport');

export const getObjectMostPopular = (): Promise<{
  data: { data: ObjectsMostPopularAnalyticsType[] };
}> => axiosInstance.get('/analytics-sport-centers/most-popular-sport-centers');

export const getObjectGraph = (): Promise<{ data: { data: ObjectAnalyticsCriteriaType[] } }> =>
  axiosInstance.get('/analytics-sport-centers/graph-filter-options');

export const getObjectFilterAnalytics = (
  query: ObjectAnalyticsFilterQueryParamsType
): Promise<{ data: { data: ObjectAnalyticsFilterResponseType } }> =>
  axiosInstance.get('/analytics-sport-centers/filter-analytics', { params: query });

//superadmin users
export const getUsersCount = (): Promise<{
  data: { data: UsersCountType };
}> => axiosInstance.get('/analytics-users/counts');

export const getLatestUsersCount = (params?: {
  datetime_from: string;
  datetime_to: string;
}): Promise<{
  data: { data: LatestUsersCountType };
}> => axiosInstance.get('/analytics-users/latest-users-counts', { params });

export const getUsersByGenders = (): Promise<{
  data: { data: UsersByGendersType };
}> => axiosInstance.get('/analytics-users/users-counts-by-genders');

// admin appointments
export const getAdminAppointmentsCounts = (
  sportCenterId: number,
  frontApp?: 'cms' | 'client' | undefined
): Promise<{
  data: { data: AdminAppointmentsCountType };
}> =>
  axiosInstance.get(`analytics-sport-centers/by-sport-center/${sportCenterId}/counts`, {
    params: {
      frontApp: frontApp
    }
  });

export const getAdminLastAppointments = (
  sportCenterId: number,
  params?: {
    frontApp?: 'cms' | 'client' | undefined;
    page?: number;
    perPage?: number;
  }
): Promise<{
  data: {
    data: AdminLastAppointmentsAnalyticsType[];
    meta: MetaType;
  };
}> =>
  axiosInstance.get(
    `analytics-sport-centers/by-sport-center/${sportCenterId}/latest-appointments`,
    { params }
  );

export const getAdminCanceledAppointments = (
  sportCenterId: number,
  params?: {
    page: number;
    perPage: number;
  }
): Promise<{
  data: {
    data: AdminCanceledAppointmentsAnalyticsType[];
    meta: MetaType;
  };
}> =>
  axiosInstance.get(
    `analytics-sport-centers/by-sport-center/${sportCenterId}/latest-canceled-appointments`,
    { params }
  );

export const getAdminObjectUsage = (
  sportCenterId: number,
  params?: {
    datetime_from: string;
    datetime_to: string;
  }
): Promise<{ data: AdminObjectUsageAnalyticsResponseType }> =>
  axiosInstance.get(
    `analytics-sport-centers/by-sport-center/${sportCenterId}/bookings-percentage`,
    {
      params
    }
  );

export const getAdminMostVisitedDays = (
  sportCenterId: number,
  params?: {
    frontApp?: 'cms' | 'client' | undefined;
    datetime_from?: string;
    datetime_to?: string;
  }
): Promise<{
  data: { data: AdminMostVisitedDaysType };
}> =>
  axiosInstance.get(`analytics-sport-centers/by-sport-center/${sportCenterId}/most-visited-days`, {
    params
  });

// admin users
export const getAdminUsersCounts = (
  sportCenterId: number,
  params?: {
    datetime_from: string;
    datetime_to: string;
  }
): Promise<{
  data: { data: AdminUsersCountType };
}> =>
  axiosInstance.get(`analytics-sport-centers/by-sport-center/${sportCenterId}/users-counts`, {
    params
  });

export const getAdminUsersPercentage = (
  sportCenterId: number
): Promise<{
  data: { data: AdminUsersPercentageType };
}> =>
  axiosInstance.get(`analytics-sport-centers/by-sport-center/${sportCenterId}/users-percentage`);

export const getAdminUsersFrequency = (
  sportCenterId: number,
  params?: {
    datetime_from: string;
    datetime_to: string;
  }
): Promise<{
  data: { data: AdminUsersFrequencyType };
}> =>
  axiosInstance.get(`analytics-sport-centers/by-sport-center/${sportCenterId}/users-frequency`, {
    params
  });
