import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// components
import {
  Button,
  ButtonProps,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  styled
} from '@mui/material';
import { DateView, MobileDatePicker } from '@mui/x-date-pickers';
//icons
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { ArrowDownIcon } from '@components/icons/icons';
//helpers
import dayjs, { Dayjs } from 'dayjs';
// theme
import theme from '@src/theme';

const StyledButton = styled((props: ButtonProps) => <Button {...props} variant="outlined" />)({
  borderRadius: '10px',
  borderColor: '#C9C9C9',
  color: theme.palette.text.secondary
});

const getDateRange = (date: Dayjs, view: DateView) => ({
  datetime_start: date.startOf(view),
  datetime_end: date.endOf(view)
});

const formatOptions: Record<DateView, { format: string; views: DateView[] }> = {
  day: { format: 'DD. MMM YYYY', views: ['year', 'month', 'day'] },
  month: { format: 'MMMM YYYY', views: ['year', 'month'] },
  year: { format: 'YYYY', views: ['year'] }
};

interface CustomDatePickerProps {
  viewType: DateView;
  onChange: (datetime_start: Dayjs, datetime_end: Dayjs) => void;
  value?: string;
  maxDate?: string;
  minDate?: string;
  withChangeView?: boolean;
  setViewType?: (view: DateView) => void;
  disableNavigation?: boolean;
}
const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  value = dayjs().toISOString(),
  onChange,
  maxDate,
  minDate,
  viewType,
  setViewType,
  withChangeView = false,
  disableNavigation = false
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Dayjs>(dayjs(value));
  const [openSelect, setOpenSelect] = useState<boolean>(false);
  const selectRef = useRef<HTMLButtonElement>(null);

  const isToday = date.isSame(dayjs(), 'day');

  const updateDate = (value: Dayjs | null, view?: DateView) => {
    const newDate = dayjs(value);
    if (newDate) {
      setDate(newDate);
      const { datetime_start, datetime_end } = getDateRange(newDate, view ?? viewType);
      onChange(datetime_start, datetime_end);
    }
  };

  const onViewChange = (newView: DateView) => {
    setViewType?.(newView);
    updateDate(date, newView);
  };

  return (
    <Stack direction="row" spacing={2} position="relative">
      {!disableNavigation && (
        <StyledButton onClick={() => updateDate(date.subtract(1, viewType))}>
          <NavigateBefore sx={{ fill: '#C9C9C9' }} />
        </StyledButton>
      )}

      <Stack
        direction="row"
        sx={{
          border: '1px solid #C9C9C9',
          borderRadius: '0.75rem',
          px: 2,
          width: '10rem'
        }}
      >
        <MobileDatePicker
          value={date}
          view={viewType}
          views={formatOptions[viewType].views}
          format={formatOptions[viewType].format}
          minDate={minDate ? dayjs(minDate) : undefined}
          maxDate={maxDate ? dayjs(maxDate) : undefined}
          disableFuture
          closeOnSelect
          onAccept={updateDate}
          slotProps={{
            textField: {
              inputProps: {
                value:
                  dayjs().isSame(date, 'day') && viewType === 'day'
                    ? t('today')
                    : date.format(formatOptions[viewType].format)
              }
            },
            actionBar: {
              actions: []
            }
          }}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            },
            '& .MuiInputBase-root': {},
            '& input': {
              cursor: 'pointer',
              padding: 1
            }
          }}
        />

        {withChangeView && (
          <>
            <IconButton ref={selectRef} onClick={() => setOpenSelect(!openSelect)} size="small">
              <ArrowDownIcon />
            </IconButton>

            <FormControl>
              <Select
                open={openSelect}
                onClose={() => setOpenSelect(false)}
                value={viewType}
                onChange={e => {
                  onViewChange(e.target.value as DateView);
                }}
                onClick={() => setOpenSelect(!openSelect)}
                displayEmpty
                sx={{ display: 'none' }}
                MenuProps={{
                  anchorEl: selectRef?.current,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  }
                }}
              >
                {Object.keys(formatOptions).map(key => (
                  <MenuItem key={key} value={key}>
                    {t(key)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </Stack>

      {!disableNavigation && (
        <StyledButton disabled={isToday} onClick={() => updateDate(date.add(1, viewType))}>
          <NavigateNext sx={{ fill: '#C9C9C9' }} />
        </StyledButton>
      )}
    </Stack>
  );
};

export default CustomDatePicker;
