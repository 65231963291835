//router
import { Outlet } from 'react-router-dom';
//pages
import AdminSettingsPage from '@pages/Admin/Settings/AdminSettingsPage';
import AdminCalendarPage from '@pages/Admin/Calendar/AdminCalendarPage';
import PriceListSettingsPage from '@pages/Admin/Settings/PriceListSettingsPage/PriceListSettingsPage';
import LanguageSettingsPage from '@pages/Admin/Settings/LanguageSettingsPage/LanguageSettingsPage';
import AdminUsersPage from '@pages/Admin/Users/AdminUsers';
import ChangePasswordPage from '@pages/Settings/ChangePasswordPage/ChangePasswordPage';
import RoleSettingsPage from '@pages/Settings/RoleSettingsPage/RoleSettingsPage';
import CreateRolePage from '@pages/Settings/RoleSettingsPage/CreateRolePage/CreateRolePage';
import AdminEmployeesPage from '@pages/Admin/Eployees/EmployeesPage';
import CreateAdminEmployeePage from '@pages/Admin/Eployees/Create/CreateAdminEmployePage';
import EditEmployeePage from '@pages/Admin/Eployees/EditAndSingle/EditEmployeePage';
import CoachesPage from '@pages/Admin/Coaches/CoachesPage';
import AddCoachesPage from '@pages/Admin/Coaches/AddCoach/AddCoachesPage';
import NonWorkingDays from '@pages/Admin/Settings/NonWorkingDays/NonWorkingDays';
import CreateNonWorkingDays from '@pages/Admin/Settings/NonWorkingDays/Create/CreateNonWorkingDays';
import ObjectProfile from '@pages/Admin/Settings/ObjectProfile/ObjectProfile';
import EditCoachesPage from '@pages/Admin/Coaches/EditCoach/EditCoachesPage';
import { SingleUserPage } from '@pages/Users/SingleUser/SingleUserPage';
import CourtSettingsPage from '@pages/Admin/Settings/CourtPage/CourtPage';
import ComingSoonPage from '@pages/ComingSoon/ComingSoonPage';
import PermanentAppointmentPage from '@pages/Admin/PermanentAppointment/PermanentAppointmentPage';
import EditPermanentAppointmentPage from '@pages/Admin/PermanentAppointment/EditPermanentAppointmentPage';
import CoachSettingsProfilePage from '@pages/Admin/Coaches/CoachCms/Settings/CoachSettingsProfilePage';
import CoachesLeasedHoursSettingsPage from '@pages/Admin/Coaches/CoachCms/Settings/CoachesLeasedHoursSettingsPage';
//components
import RequireAuth from '@components/Auth/RequireAuth';
import AddAdminUsers from '@components/AddAdminUsers/AddAdminUsers';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';
// images
import categoriesAdminImage from '@src/assets/images/comingSoon/categoriesAdmin.png';

import loginImage from '@src/assets/images/loginImage.png';
import AdminUserOverview from '@pages/Users/AdminUserOverview/AdminUserOverview';
import AdminAnalyticsPage from '@pages/Admin/Analytics/AdminAnalyticsPage';
import AdminFinancePage from '@pages/Admin/Finances/AdminFinancePage';
import AppFinancesPage from '@pages/Admin/Finances/AppFinancesPage/AppFinancesPage';
import PermanentAppointmentsFinances from '@pages/Admin/Finances/PermanentAppointments/PermanentAppointmentsFinancesPage';
import CoachesFinancesPage from '@pages/Admin/Finances/CoachesFinance/CoachesFinancesPage';
import AdminAppointmentsAnalyticsPage from '@pages/Admin/Analytics/Appointments/AdminAppointmentsAnalytics';
import AdminUsersAnalyticsPage from '@pages/Admin/Analytics/Users/AdminUsersAnalytics';

export const adminRoutes = {
  path: '/',
  element: <RequireAuth allowedPermissions={[]} />,
  children: [
    {
      path: 'calendar/:sportCenterId',
      element: <RequireAuth allowedPermissions={[PermissionsEnum.read_appointments]} />,
      children: [
        {
          path: '',
          element: <AdminCalendarPage />
        }
      ]
    },
    {
      path: 'users/:sportCenterId',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <AdminUsersPage />
        },
        {
          path: 'create-user',
          element: <AddAdminUsers />
        },
        {
          path: 'edit-user/:userId',
          element: <AdminUserOverview />
        },
        {
          path: 'categories',
          element: <ComingSoonPage imageURL={categoriesAdminImage} />
        },
        {
          path: 'regulars',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <PermanentAppointmentPage />
            },
            {
              path: 'edit-regular/:permanentAppointmentId',
              element: <EditPermanentAppointmentPage />
            }
          ]
        },
        {
          path: 'coaches',
          element: <CoachesPage />
        },
        {
          path: 'coaches/create-coach',
          element: <AddCoachesPage />
        },
        {
          path: 'coaches/edit-coach/:coachId',
          element: <EditCoachesPage />
        },
        {
          path: ':userId',
          element: <SingleUserPage />
        }
      ]
    },

    {
      path: 'employees/:sportCenterId',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <AdminEmployeesPage />
        },
        {
          path: 'create-employee',
          element: <CreateAdminEmployeePage />
        },
        {
          path: 'edit-employee/:employeeID',
          element: <EditEmployeePage />
        }
      ]
    },
    {
      path: 'finance/:sportCenterId',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <AdminFinancePage />
        },
        {
          path: 'app',
          element: <AppFinancesPage />
        },
        {
          path: 'regulars',
          element: <PermanentAppointmentsFinances />
        },
        {
          path: 'superusers',
          element: <CoachesFinancesPage />
        },
        {
          path: 'categories',
          element: <div> kategorije finansije</div>
        }
      ]
    },

    {
      path: 'analytics/:sportCenterId',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <AdminAnalyticsPage />
        },
        {
          path: 'appointments',
          element: <AdminAppointmentsAnalyticsPage />
        },
        {
          path: 'users',
          element: <AdminUsersAnalyticsPage />
        }
        // {
        //   path: 'courts',
        //   element: <div>courts</div>
        // },
      ]
    },

    {
      path: 'tournaments/:sportCenterId',
      element: <ComingSoonPage imageURL={loginImage} />
    },
    {
      path: 'leagues/:sportCenterId',
      element: <ComingSoonPage imageURL={loginImage} />
    },
    {
      path: 'settings/:sportCenterId',
      element: <AdminSettingsPage />
    },
    {
      path: 'settings/:sportCenterId/object-profile',
      element: <ObjectProfile />
    },
    {
      path: 'settings/:sportCenterId/pricelist',
      element: <PriceListSettingsPage />
    },
    {
      path: 'settings/:sportCenterId/fields-and-sports',
      element: <CourtSettingsPage />
    },
    {
      path: 'settings/:sportCenterId/non-working-days',
      element: <Outlet />,
      children: [
        { path: '', element: <NonWorkingDays /> },
        {
          path: 'create-non-working-days',
          element: <CreateNonWorkingDays />
        },
        {
          path: 'edit-non-working-days/:ruleId',
          element: <CreateNonWorkingDays />
        }
      ]
    },
    {
      path: 'settings/:sportCenterId/access',
      element: <RequireAuth allowedPermissions={[PermissionsEnum.read_roles]} />,
      children: [
        { path: '', element: <RoleSettingsPage /> },
        { path: 'create-role', element: <CreateRolePage /> },
        { path: 'edit-role/:roleId', element: <CreateRolePage /> }
      ]
    },
    {
      path: 'settings/:sportCenterId/appendices',
      element: <div>appendices</div>
    },
    {
      path: 'settings/:sportCenterId/discounts',
      element: <div>doscount</div>
    },
    {
      path: 'settings/:sportCenterId/language',
      element: <LanguageSettingsPage />
    },
    {
      path: 'settings/:sportCenterId/change-password',
      element: <ChangePasswordPage />
    },
    // TODO: Return this after MVP
    // {
    //   path: 'settings/:sportCenterId/packages',
    //   element: <div>Paketi</div>
    // }
    {
      path: 'settings/:sportCenterId/profile',
      element: <CoachSettingsProfilePage />
    },
    {
      path: 'settings/:sportCenterId/leased-hours/:coachId',
      element: <Outlet />,
      children: [
        { path: '', element: <CoachesLeasedHoursSettingsPage /> },
        {
          path: ':leasedHourdId',
          element: <div>single zakupljeni sati </div>
        }
      ]
    }
  ]
};
