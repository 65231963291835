import { Fragment, ReactNode } from 'react';
import { Button, Card, CardHeader, Divider, Typography } from '@mui/material';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';

type ChartCardProps = {
  title: string;
  count: number | string;
  subtitle: string;
  next?: string;
  children?: React.ReactNode;
  action?: ReactNode;
};

const ChartCard = ({
  title,
  count,
  subtitle,
  next,
  children,
  action
}: ChartCardProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Card sx={{ height: '100%', overflowX: 'auto' }}>
      <CardHeader
        title={
          <Fragment>
            <Typography variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="h5" component="div">
              {count}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {subtitle}
            </Typography>
          </Fragment>
        }
        action={
          action ? action : next && <Button endIcon={<NavigateNext />}>{t('view_all')}</Button>
        }
        titleTypographyProps={{ fontWeight: 'bold' }}
      />

      <Divider />
      {children}
    </Card>
  );
};

export default ChartCard;
