// hooks
import { Controller, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// components
import {
  Typography,
  TextField,
  Box,
  Grid,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import CardWrapper from '@containers/CardWrapper/CardWrapper';
import CustomTimePicker from '@components/CustomTimePicker/CustomTimePicker';
// types
import { Control } from 'react-hook-form';
import { ChangeEvent } from 'react';
import { CreateSportCenterType } from '@interfaces/SportCenters/SportCenter';
import { BenefitsType } from '@interfaces/SportCenters/Benefits';
//helpers
import {
  handleBenefitsChecked,
  getAvailableTimesForTimePicker,
  getTranslateName
} from '@src/helpers/utility';

type OtherDataProps = {
  control: Control<CreateSportCenterType>;
  benefitsRes: BenefitsType;
  isBenefitsChecked: number[];
  setIsBenefitsChecked: (benefits: number[]) => void;
  reqMessage: string;
  getValues: UseFormGetValues<CreateSportCenterType>;
  wholeWorkingDay: boolean;
  handleChangeWholeWorkingDay: (event: ChangeEvent<HTMLInputElement>) => void;
  wholeSaturdayDay: { on: boolean; off: boolean };
  handleChangeWholeSaturdayDay: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeOffWholeSaturdayDay: (event: ChangeEvent<HTMLInputElement>) => void;
  wholeSundayDay: { on: boolean; off: boolean };
  handleChangeWholeSundayDay: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeOffWholeSundayDay: (event: ChangeEvent<HTMLInputElement>) => void;
};
const OtherData = ({
  control,
  benefitsRes,
  isBenefitsChecked,
  setIsBenefitsChecked,
  reqMessage,
  getValues,
  wholeWorkingDay,
  handleChangeWholeWorkingDay,
  wholeSaturdayDay,
  handleChangeWholeSaturdayDay,
  handleChangeOffWholeSaturdayDay,
  wholeSundayDay,
  handleChangeWholeSundayDay,
  handleChangeOffWholeSundayDay
}: OtherDataProps) => {
  const { t } = useTranslation();
  const lng = localStorage.getItem('lng');
  const availableTimes: string[] = getAvailableTimesForTimePicker(0, 24);
  const validateWokweek: boolean =
    parseInt(getValues('working_hours.workweek_from')) >=
    parseInt(getValues('working_hours.workweek_to'));

  const validateSaturday: boolean =
    getValues('working_hours.saturday_from') !== '00:00' &&
    getValues('working_hours.saturday_to') !== '00:00'
      ? parseInt(getValues('working_hours.saturday_from')) >=
        parseInt(getValues('working_hours.saturday_to'))
      : false;

  const validateSunday: boolean =
    getValues('working_hours.sunday_from') !== '00:00' &&
    getValues('working_hours.sunday_to') !== '00:00'
      ? parseInt(getValues('working_hours.sunday_from')) >=
        parseInt(getValues('working_hours.sunday_to'))
      : false;
  return (
    <CardWrapper
      title={
        <Typography variant="h6" mt={2} sx={{ width: '100%' }}>
          {t('sportCenters.stepOne.otherData.title')}
        </Typography>
      }
      subtitle={
        <Typography variant="subtitle2" color="GrayText" sx={{ pb: 2 }}>
          {t('sportCenters.stepOne.otherData.subtitle')}
        </Typography>
      }
      isForm={true}
    >
      <Grid container pb={2}>
        {/* Opis objekta */}
        <Grid item xs={12}>
          <Controller
            name="basic_info.description_translates.sr"
            control={control}
            rules={{ required: !lng || lng === 'sr' ? reqMessage : false }}
            render={({ field, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                label={
                  !lng || lng === 'sr'
                    ? t('descriptionSerbian') + ' *'
                    : t('descriptionSerbianOptional')
                }
                variant="outlined"
                placeholder={t('description_placeholder').toString()}
                multiline
                sx={{ width: '100%' }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="basic_info.description_translates.en"
            control={control}
            rules={{ required: lng === 'en' ? reqMessage : false }}
            render={({ field, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                label={
                  lng === 'en' ? t('descriptionEnglish') + ' *' : t('descriptionEnglishOptional')
                }
                variant="outlined"
                placeholder={t('description_placeholder').toString()}
                multiline
                sx={{ width: '100%', mt: 2 }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>

        <Typography sx={{ width: '100%' }} mt={2} mb={1}>
          {t('sportCenters.stepOne.otherData.working_hours')}
        </Typography>

        {/* Radni dani */}
        <Grid item xs={12} lg={4}>
          <Typography variant="subtitle2" color="grayText" pb={1}>
            {t('workweek')}
          </Typography>
          <Box display={'flex'} flexWrap="wrap" gap={1}>
            <Controller
              name="working_hours.workweek_from"
              control={control}
              render={({ field, fieldState: { error, invalid } }) => (
                <CustomTimePicker
                  disabled={wholeWorkingDay}
                  field={field}
                  id="working_hours.workweek_from"
                  availableTimes={availableTimes}
                  labelId="working_hours.workweek_from-label"
                  label={t('from') + ' *'}
                  invalid={invalid}
                  helperText={
                    validateWokweek
                      ? t('stepFour.newPriceRule.price_rule.time_start_error_message').toString()
                      : error && error.message
                  }
                  className="timePickerStepOne"
                  required
                />
              )}
            />
            <Controller
              name="working_hours.workweek_to"
              control={control}
              render={({ field, fieldState: { error, invalid } }) => (
                <CustomTimePicker
                  disabled={wholeWorkingDay}
                  field={field}
                  id="working_hours.workweek_to"
                  availableTimes={availableTimes}
                  labelId="working_hours.workweek_to-label"
                  label={t('to') + ' *'}
                  invalid={invalid}
                  helperText={
                    validateWokweek
                      ? t('stepFour.newPriceRule.price_rule.time_start_error_message').toString()
                      : error && error.message
                  }
                  className="timePickerStepOne"
                  required
                />
              )}
            />
          </Box>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Checkbox
              checked={wholeWorkingDay}
              value={wholeWorkingDay}
              onChange={handleChangeWholeWorkingDay}
            />
            <Typography variant="subtitle2" color="grayText">
              {t('sportCenters.stepOne.otherData.work_all_day')}
            </Typography>
          </Box>
        </Grid>
        {/* Subota */}
        <Grid item xs={12} lg={4}>
          <Typography color="grayText" variant="subtitle2" pb={1}>
            {t('sat')}
          </Typography>
          <Box display={'flex'} flexWrap="wrap" gap={1}>
            <Controller
              name="working_hours.saturday_from"
              control={control}
              render={({ field, fieldState: { error, invalid } }) => (
                <CustomTimePicker
                  disabled={wholeSaturdayDay.on || wholeSaturdayDay.off}
                  field={field}
                  id="working_hours.saturday_from"
                  availableTimes={availableTimes}
                  labelId="working_hours.saturday_from-label"
                  label={t('from') + ' *'}
                  invalid={invalid}
                  helperText={
                    validateSaturday && !wholeSaturdayDay.off
                      ? t('stepFour.newPriceRule.price_rule.time_start_error_message').toString()
                      : error && error.message
                  }
                  className="timePickerStepOne"
                  required
                />
              )}
            />
            <Controller
              name="working_hours.saturday_to"
              control={control}
              render={({ field, fieldState: { error, invalid } }) => (
                <CustomTimePicker
                  disabled={wholeSaturdayDay.on || wholeSaturdayDay.off}
                  field={field}
                  id="working_hours.saturday_to"
                  availableTimes={availableTimes}
                  labelId="working_hours.saturday_to-label"
                  label={t('to') + ' *'}
                  invalid={invalid}
                  helperText={
                    validateSaturday && !wholeSaturdayDay.off
                      ? t('stepFour.newPriceRule.price_rule.time_start_error_message').toString()
                      : error && error.message
                  }
                  className="timePickerStepOne"
                  required
                />
              )}
            />
          </Box>
          <Box display={'flex'} flexDirection={'row'}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Checkbox
                checked={wholeSaturdayDay.on}
                value={wholeSaturdayDay.on}
                onChange={handleChangeWholeSaturdayDay}
              />
              <Typography variant="subtitle2" color="grayText">
                {t('sportCenters.stepOne.otherData.work_all_day')}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Checkbox
                checked={wholeSaturdayDay.off}
                value={wholeSaturdayDay.off}
                onChange={handleChangeOffWholeSaturdayDay}
              />
              <Typography variant="subtitle2" color="grayText">
                {t('sportCenters.stepOne.otherData.off_day')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* Nedelja */}
        <Grid item xs={12} lg={4}>
          <Typography color="grayText" variant="subtitle2" pb={1}>
            {t('sun')}
          </Typography>
          <Box display={'flex'} flexWrap="wrap" gap={1}>
            <Controller
              name="working_hours.sunday_from"
              control={control}
              defaultValue="08:00"
              render={({ field, fieldState: { error, invalid } }) => (
                <CustomTimePicker
                  disabled={wholeSundayDay.on || wholeSundayDay.off}
                  field={field}
                  id="working_hours.sunday_from"
                  availableTimes={availableTimes}
                  labelId="working_hours.sunday_from-label"
                  label={t('from') + ' *'}
                  invalid={invalid}
                  helperText={
                    validateSunday && !wholeSundayDay.off
                      ? t('stepFour.newPriceRule.price_rule.time_start_error_message').toString()
                      : error && error.message
                  }
                  className="timePickerStepOne"
                  required
                />
              )}
            />
            <Controller
              name="working_hours.sunday_to"
              control={control}
              render={({ field, fieldState: { error, invalid } }) => (
                <CustomTimePicker
                  disabled={wholeSundayDay.on || wholeSundayDay.off}
                  field={field}
                  id="working_hours.sunday_to"
                  availableTimes={availableTimes}
                  labelId="working_hours.sunday_to-l"
                  label={t('to') + ' *'}
                  invalid={invalid}
                  helperText={
                    validateSunday && !wholeSundayDay.off
                      ? t('stepFour.newPriceRule.price_rule.time_start_error_message').toString()
                      : error && error.message
                  }
                  className="timePickerStepOne"
                  required
                />
              )}
            />
          </Box>
          <Box display={'flex'} flexDirection={'row'}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Checkbox
                checked={wholeSundayDay.on}
                value={wholeSundayDay.on}
                onChange={handleChangeWholeSundayDay}
              />
              <Typography variant="subtitle2" color="grayText">
                {t('sportCenters.stepOne.otherData.work_all_day')}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Checkbox
                checked={wholeSundayDay.off}
                value={wholeSundayDay.off}
                onChange={handleChangeOffWholeSundayDay}
              />
              <Typography variant="subtitle2" color="grayText">
                {t('sportCenters.stepOne.otherData.off_day')}
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Pogodnosti */}

        <Grid item xs={12} justifyContent="start" alignItems="center">
          <Typography variant="subtitle1" mt={2}>
            {t('benefits')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="benefits"
            control={control}
            defaultValue={[]}
            rules={{ required: reqMessage }}
            render={({ field, fieldState: { error, invalid } }) => (
              <>
                {error && <FormHelperText error>{error.message}</FormHelperText>}
                <FormGroup
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: { md: '400px', xs: 'max-content' },
                    mt: 1
                  }}
                >
                  {benefitsRes &&
                    benefitsRes.map((benefit, index) => {
                      let benefitName = getTranslateName(
                        lng,
                        benefit.name_translates,
                        benefit.name
                      );
                      return (
                        <FormControlLabel
                          key={benefit.id}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              color: `${invalid ? 'red' : '#6B7280'}`
                            }
                          }}
                          control={
                            <Checkbox
                              {...field}
                              checked={isBenefitsChecked.includes(benefit.id)}
                              name={benefit.name}
                              value={benefit.id}
                              onChange={e =>
                                field.onChange(
                                  handleBenefitsChecked(e, isBenefitsChecked, setIsBenefitsChecked)
                                )
                              }
                            />
                          }
                          label={benefitName}
                        />
                      );
                    })}
                </FormGroup>
              </>
            )}
          />
        </Grid>
      </Grid>
    </CardWrapper>
  );
};

export default OtherData;
