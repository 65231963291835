import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Svg,
  G,
  Path,
  Rect,
  Font
} from '@react-pdf/renderer';
// helpers
import { randomColorsForGraphs as COLORS } from '@helpers/utility';
import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import regularFontSource from '@src/assets/fonts/Inter-Regular.ttf';
import boldFontSource from '@src/assets/fonts/Inter-Bold.ttf';
import {
  AdminMostVisitedDaysType,
  AdminObjectUsageAnalyticsResponseType
} from '@interfaces/analytics/analytics';

const getCanceledAppointmentsHeader = (t: TFunction) => [
  t('user'),
  t('cancelation_time'),
  t('appointment'),
  t('sport'),
  t('canceled_from')
];

Font.register({
  family: 'Inter',
  src: regularFontSource,
  fontWeight: 'normal'
});

Font.register({
  family: 'Inter',
  src: boldFontSource,
  fontWeight: 'bold'
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 20,
    fontFamily: 'Inter'
  },
  title: { fontSize: 20, textAlign: 'center', textOverflow: 'ellipsis' },
  chartContainer: {},
  chartTitle: { marginTop: 20, fontSize: 16, fontWeight: 'bold' },
  chartSubtitle: { fontSize: 13, color: '#777', marginTop: 8 },
  chartValue: { marginTop: 8, fontSize: 16, fontWeight: 'bold' },

  legend: {
    marginTop: 20,
    fontSize: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 500
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5,
    gap: 5
  },
  colorBox: {
    width: 10,
    height: 10,
    marginRight: 5
  },

  cardContainer: {
    width: '100%',
    flexDirection: 'row',
    gap: 5,
    marginTop: 5
  },
  contentContainer: {
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
    padding: 12,
    justifyContent: 'center',
    backgroundColor: '#f9f9f9',
    width: '50%'
  },
  valueText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#000'
  },
  labelText: {
    fontSize: 10,
    color: '#777',
    marginTop: 4
  },
  tableContainerWrapper: { paddingVertical: 20 },
  tableTitle: { fontSize: 14, marginBottom: 10, fontWeight: 'bold' },
  tableContainer: {
    marginTop: 10,
    border: 1,
    borderColor: '#ddd'
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: 1,
    borderColor: '#ddd'
  },
  tableHeader: {
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold'
  },
  tableCell: {
    padding: 8,
    fontSize: 10,
    flex: 1,
    borderRight: 1,
    borderColor: '#ddd',
    textAlign: 'left'
  },
  lastCell: {
    borderRightWidth: 0
  },

  barChartContainer: {
    borderBottom: 1,
    borderLeft: 1,
    borderColor: '#ddd'
  },
  xAxisLabel: {
    fontSize: 10,
    textAlign: 'center',
    width: 60
  },
  barLabel: {
    fontSize: 15,
    textAlign: 'center',
    marginBottom: 3
  }
});

const AnalyticsStatementPDF = ({
  objectName,
  pieData,
  barData,
  lastAppointments,
  canceledAppointments,
  counts
}: {
  objectName: string;
  pieData: AdminObjectUsageAnalyticsResponseType;
  barData: AdminMostVisitedDaysType;
  lastAppointments: any[];
  canceledAppointments: any[];
  counts: {
    totalUsers: number;
    newUsers: number;
    appUsers: number;
    backUsers: number;
  };
}) => {
  const { t } = useTranslation();

  const maxValue = Math.max(...barData.data.map((item: any) => item.appointments_count));

  const cumulativeAngles = pieData.data.reduce<number[]>((acc, item) => {
    const prev = acc.length ? acc[acc.length - 1] : 0;
    return [...acc, prev + (item.value / 100) * 360];
  }, []);

  const createPiePath = (index: number, radius: number) => {
    const startAngle = index === 0 ? 0 : cumulativeAngles[index - 1];
    const endAngle = cumulativeAngles[index];

    const radians = (angle: number) => (Math.PI / 180) * angle;

    const startX = radius + radius * Math.cos(radians(startAngle));
    const startY = radius - radius * Math.sin(radians(startAngle));
    const endX = radius + radius * Math.cos(radians(endAngle));
    const endY = radius - radius * Math.sin(radians(endAngle));
    const largeArcFlag = endAngle - startAngle >= 180 ? 1 : 0;

    return `M${radius},${radius} L${startX},${startY} A${radius},${radius} 0 ${largeArcFlag} 0 ${endX},${endY} Z`;
  };

  const getTextPosition = (index: number) => {
    const startAngle = index === 0 ? 0 : cumulativeAngles[index - 1];
    const endAngle = cumulativeAngles[index];
    const midAngle = (startAngle + endAngle) / 2;

    const radians = (angle: number) => (Math.PI / 180) * angle;
    const textX = 100 + 60 * Math.cos(radians(midAngle));
    const textY = 100 - 60 * Math.sin(radians(midAngle));

    return {
      x: textX,
      y: textY
    };
  };

  return (
    <Document title={`${t('admin_analytics.analytics_report')} ${objectName}`}>
      <Page style={styles.page}>
        <Text style={styles.title}>{`${t('admin_analytics.analytics_report')} ${objectName}`}</Text>
        <View style={styles.cardContainer}>
          <View style={styles.contentContainer}>
            <Text style={styles.valueText}>{counts.totalUsers}</Text>
            <Text style={styles.labelText}>{t('admin_analytics.total_users')}</Text>
          </View>
          <View style={styles.contentContainer}>
            <Text style={styles.valueText}>{counts.newUsers}</Text>
            <Text style={styles.labelText}>{t('admin_analytics.total_new_users')}</Text>
          </View>
        </View>
        <View style={styles.cardContainer}>
          <View style={styles.contentContainer}>
            <Text style={styles.valueText}>{counts.appUsers}</Text>
            <Text style={styles.labelText}>{t('admin_analytics.total_app_users')}</Text>
          </View>
          <View style={styles.contentContainer}>
            <Text style={styles.valueText}>{counts.backUsers}</Text>
            <Text style={styles.labelText}>{t('admin_analytics.total_back_users')}</Text>
          </View>
        </View>

        <View style={styles.chartContainer}>
          <Text style={styles.chartTitle}>
            {t('admin_analytics.appointments_played')} : {barData.total}
          </Text>
          <Text style={styles.chartSubtitle}>
            {t('superadmin_analytics.appointments_number_this_month')}
          </Text>

          <View>
            <Svg height="200" width="450" viewBox="0 0 420 200" style={styles.barChartContainer}>
              {barData.data.map((item, index) => (
                <Fragment key={index}>
                  <G key={index}>
                    <Rect
                      x={index * 60}
                      y={200 - (item.appointments_count / maxValue) * 150}
                      width="20"
                      height={(item.appointments_count / maxValue) * 150}
                      fill={COLORS[index % COLORS.length]}
                    />
                  </G>
                  <Text
                    key={index}
                    x={index * 60}
                    y={200 - (item.appointments_count / maxValue) * 150 - 5}
                    fill="black"
                    style={styles.barLabel}
                  >
                    {`${item.appointments_count}`}
                  </Text>
                </Fragment>
              ))}
            </Svg>
          </View>
          <View style={{ flexDirection: 'row' }}>
            {barData.data.map((item, index) => (
              <Text key={index} style={styles.xAxisLabel}>
                {item.day}
              </Text>
            ))}
          </View>
        </View>

        <View style={styles.tableContainerWrapper}>
          <Text style={styles.tableTitle}>{t('admin_analytics.last_reservated_appointments')}</Text>
          <View style={styles.tableContainer}>
            {/* Header */}
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={[styles.tableCell]}>{t('user')}</Text>
              <Text style={[styles.tableCell]}>{t('time')}</Text>
              <Text style={[styles.tableCell]}>{t('date')}</Text>
              <Text style={[styles.tableCell, styles.lastCell]}>{t('sport')}</Text>
            </View>

            {/* Rows */}
            {lastAppointments.map((row, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{row.user.name}</Text>
                <Text style={styles.tableCell}>{row.time}</Text>
                <Text style={styles.tableCell}>{row.date}</Text>
                <Text style={[styles.tableCell, styles.lastCell]}>{row.court_sport}</Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
      <Page style={styles.page}>
        <View style={styles.tableContainerWrapper}>
          <Text style={styles.tableTitle}>{t('admin_analytics.last_canceled_appointments')}</Text>
          <View style={styles.tableContainer}>
            {/* Header */}
            <View style={[styles.tableRow, styles.tableHeader]}>
              {getCanceledAppointmentsHeader(t).map((header, index) => (
                <Text key={index} style={[styles.tableCell]}>
                  {header}
                </Text>
              ))}
            </View>

            {/* Rows */}
            {canceledAppointments.map((row, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{row.user.name}</Text>
                <Text style={styles.tableCell}>{row.canceled_at}</Text>
                <Text style={styles.tableCell}>{row.appointment}</Text>
                <Text style={styles.tableCell}>{row.court_sport}</Text>
                <Text style={[styles.tableCell, styles.lastCell]}>{row.canceled_by}</Text>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.chartContainer}>
          <Text style={styles.chartTitle}>
            {t('admin_analytics.object_usage')} : {pieData.total} %
          </Text>
          <Text style={styles.chartSubtitle}>
            {t('superadmin_analytics.appointments_number_this_month')}
          </Text>
          <Svg width="500" height="200" viewBox="0 0 200 200" style={{ marginTop: 5 }}>
            {pieData.data.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Path
                    d={createPiePath(index, 100)}
                    fill={item.name === 'unbooked' ? '#c3c3c3' : COLORS[index % COLORS.length]}
                  />
                  <Text
                    fill="white"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    {...getTextPosition(index)}
                  >
                    {`${item.value} %`}
                  </Text>
                </React.Fragment>
              );
            })}
          </Svg>
          <View style={styles.legend}>
            {pieData.data.map((item, index) => (
              <View key={index} style={styles.legendItem}>
                <Svg width="10" height="10">
                  <Rect
                    width="10"
                    height="10"
                    fill={item.name === 'unbooked' ? '#c3c3c3' : COLORS[index % COLORS.length]}
                  />
                </Svg>
                <Text>
                  {item.name === 'unbooked' ? t('free') : item.name} ({item.value}%)
                </Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default AnalyticsStatementPDF;
