import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
//services
import { useDeleteSportCenter } from '@api/mutations/sportCenter/sportCenter';
import { useSingleSportCenter } from '@api/queries/sportCenter/sportCenter';
import { useUser } from '@api/mutations/user/user';
import { usePostImpersonate } from '@api/mutations/impersonation/impersonation';
import { useSetLoginUser } from '@hooks/useLoginUser/useSetLoginUser';
//interface
import { ErrorResponse } from '@interfaces/apiResponse';
import { SportCenterResponse } from '@interfaces/SportCenters/SportCenter';
import { User, UserErrorResponse } from '@interfaces/user/user';
//components
import { Box, Button, Grid } from '@mui/material';
import Loading from '@components/Loading/Loading';
import ProfileInfoCard from '@containers/ProfileInfoCard/ProfileInfoCard';

//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
//atoms
import { popupAtom } from '@atoms/popupAtom';

const SportCenterOverviewLayout = (): JSX.Element => {
  const { isLoading, data } = useSingleSportCenter(useParams().objectId, onSuccess, onError);
  const { mutate: deleteSportCenter } = useDeleteSportCenter(onDeleteSuccess, onDeleteError);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setUserCMS } = useSetLoginUser();
  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);

  function onSuccess(res: SportCenterResponse | undefined) {}
  function onError(error: Error) {
    setPopup({
      open: true,
      title: error.message,
      content: '',
      variant: 'error'
    });
  }
  function onDeleteSuccess() {
    navigate(-1);
  }
  function onDeleteError(error: ErrorResponse) {
    setPopup({
      open: true,
      title: error.message,
      content: '',
      variant: 'error'
    });
  }

  const onUserSuccess = (data: User) => {
    setUserCMS(data);
  };
  const onUserError = (err: UserErrorResponse): void => {
    setPopup({
      open: true,
      title: err.data.message,
      content: '',
      variant: 'error'
    });
  };
  const { mutate: me } = useUser(onUserSuccess, onUserError);

  const onImpersonateSuccess = () => {
    navigate('/');
    me('');
  };
  const onImpersonateError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };
  const { mutate: postImpersonate } = usePostImpersonate(onImpersonateSuccess, onImpersonateError);

  const handleImpersonate = (owner_id: string) => {
    postImpersonate(owner_id);
  };

  if (isLoading) {
    return <Loading />;
  }
  if (!data) {
    navigate(-1);
    return <div>error</div>;
  }

  return (
    <Box mx={5} mt={2}>
      <ProfileInfoCard
        name={data.data.name}
        image={data.data.logo ? data.data.logo.src : ''}
        // lastSeen="Poslednji put vidjen: 16:48; 23.03.2023. "
        otherInfo={[
          {
            title: t('email'),
            value: data.data.email
          },
          {
            title: t('phone_number'),
            value: data.data.phone
          },
          {
            title: t('address'),
            value: data.data.address
          }
        ]}
        location={data.data.city}
        // handleDeactivate={() => {}}
        handleDelete={() => {
          setPopup({
            open: true,
            title: t('sportCenters.deletePopupTitle') + '  ' + data.data.name,
            content: t('sportCenters.deleteMessageSingle'),
            variant: 'delete',
            onClick: () => {
              data.data.id && deleteSportCenter(data.data.id);
              resetPopup();
            }
          });
        }}
        handleAccess={() => {
          handleImpersonate(data.data.owner_id.toString());
        }}
      />

      <Grid container spacing={2} my={3}>
        <Grid item>
          <NavLink
            to={`/objects/object/${data.data.id}/object-analytics`}
            style={{ textDecoration: 'none' }}
            replace={true}
          >
            {({ isActive }) => (
              <Button variant="tab" color={isActive ? 'primary' : 'secondary'}>
                {t('sidebar.analytics')}
              </Button>
            )}
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={`/objects/object/${data.data.id}/object-finance`}
            style={{ textDecoration: 'none' }}
            replace={true}
          >
            {({ isActive }) => (
              <Button variant="tab" color={isActive ? 'primary' : 'secondary'}>
                {t('sidebar.finance')}
              </Button>
            )}
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            to={`/objects/object/${data.data.id}`}
            end
            style={{ textDecoration: 'none' }}
            replace={true}
          >
            {({ isActive }) => (
              <Button variant="tab" color={isActive ? 'primary' : 'secondary'}>
                {t('sidebar.aboutObject')}
              </Button>
            )}
          </NavLink>
        </Grid>
      </Grid>

      <Box height="100vh">
        <Outlet context={[data?.data]} />
      </Box>
    </Box>
  );
};

export default SportCenterOverviewLayout;
