import React, { useEffect, useMemo, useRef, useState } from 'react';
//components
import { Box, Grid } from '@mui/material';
import AnalyticsCard from '@components/AnalyticsCard/AnalyticsCard';
import ChartCard from '@components/ChartCard/ChartCard';
import MostVisitedSportCenters from '@components/MostVisitedSportsCenters/MostVisitedSportCenters';
import LineChartSM from '@components/Charts/LineChart/LineChart';
import DataCard from '@components/DataCard/DataCard';
//hooks
import { useTranslation } from 'react-i18next';
//api
import {
  useObjectAnalyticsCounts,
  useObjectAppointmentsCount,
  useObjectFilterAnalytics,
  useObjectGraph,
  useObjectMostPopularCounts
} from '@api/queries/analytics/analytics';
//type
import { MRT_ColumnDef } from 'material-react-table';
import {
  ObjectAnalyticsFilterOptionsType,
  ObjectsMostPopularAnalyticsType
} from '@interfaces/analytics/analytics';
import dayjs, { Dayjs } from 'dayjs';
// recoil
import { useSetRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';

const SportCentersAnalytics: React.FC = (): JSX.Element => {
  const [selected, setSelected] = useState<ObjectAnalyticsFilterOptionsType>(
    'most_reservations_from_app'
  );
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('month'));

  const { t } = useTranslation();

  const setPopup = useSetRecoilState(popupAtom);

  const onError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: err.name,
      variant: 'error'
    });
  };

  const { data: count } = useObjectAnalyticsCounts(onError);
  const { data: objectAppointments } = useObjectAppointmentsCount(onError);
  const { data: mostPopularObject } = useObjectMostPopularCounts(onError);
  const { data: criterion } = useObjectGraph(onError);
  const { data: filterData } = useObjectFilterAnalytics(
    {
      filter_option: selected,
      datetime_from: startDate && endDate ? startDate.format('YYYY-MM-DD HH:mm') : undefined,
      datetime_to: endDate && startDate ? endDate.format('YYYY-MM-DD HH:mm') : undefined
    },
    onError
  );

  const columns = useMemo<MRT_ColumnDef<ObjectsMostPopularAnalyticsType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('title')
      },
      {
        accessorKey: 'value',
        header: t('superadmin_analytics.appointments_number')
      }
    ],
    []
  );

  return (
    <Box px={3} py={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticsCard
            header={t('superadmin_analytics.total_objects')}
            content={count?.sport_centers.total_count ?? '0'}
            percent={count?.sport_centers.last_month_diff_in_percentage ?? '0'}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticsCard
            header={t('superadmin_analytics.total_courts')}
            content={count?.courts.total_count ?? '0'}
            percent={count?.courts.last_month_diff_in_percentage ?? '0'}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticsCard
            header={t('superadmin_analytics.total_activities')}
            content={count?.activities.total_count ?? '0'}
            percent={count?.activities.last_month_diff_in_percentage ?? '0'}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} sm={12} md={8}>
          <ChartCard
            title={t('superadmin_analytics.trend_most_popular_activities')}
            count={objectAppointments?.appointments_total_count ?? 0}
            subtitle={t('superadmin_analytics.appointments_number_this_month')}
          >
            <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
              <LineChartSM
                data={objectAppointments?.appointments_count_by_day_per_sport ?? []}
                yKey={'day'}
              />
            </Box>
          </ChartCard>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <DataCard
            title={t('superadmin_analytics.most_popular_objects')}
            columns={columns}
            data={mostPopularObject ?? []}
            showHeader
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 3 }}>
        <MostVisitedSportCenters
          criteria={criterion ?? []}
          data={filterData}
          selected={selected}
          setSelected={setSelected}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Box>
    </Box>
  );
};

export default SportCentersAnalytics;
