import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
//api
import {
  useGetObjectsAllLatestAppointmentFinance,
  useGetObjectsTotalRevenue,
  useGetObjectsTotalRevenueInPeriod
} from '@api/queries/finance/finance';
//recoil
import { popupAtom } from '@atoms/popupAtom';
//components
import AnalyticsCard from '@components/AnalyticsCard/AnalyticsCard';
import ChartCard from '@components/ChartCard/ChartCard';
import AreaChartSM from '@components/Charts/AreaChart/AreaChart';
import CustomDatePicker from '@components/CustomDatePicker/CustomDatePicker';
import { Box, Button, Grid } from '@mui/material';
//icons
import { ClockIcon, HouseIcon, MobileIcon } from '@components/icons/icons';
//type
import { AxiosResponse } from 'axios';
import { SportCenterResponseType } from '@interfaces/SportCenters/SportCenter';
//helpers
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';

const SportCenterOverviewFinance = () => {
  const params = useParams();
  const objectId = params.objectId ? +params.objectId : 0;

  const setPopup = useSetRecoilState(popupAtom);

  const { t } = useTranslation();

  const [month, setMonth] = useState({
    datetime_from: dayjs().startOf('month'),
    datetime_to: dayjs()
  });
  const [sportCenter] = useOutletContext<[SportCenterResponseType]>();

  const onError = (err: AxiosResponse | undefined) => {
    setPopup({
      open: true,
      title: err?.data.message ?? 'Error',
      content: '',
      variant: 'error'
    });
  };

  const { data: totalRevenue } = useGetObjectsTotalRevenue(objectId, onError);
  const { data: totalRevenueInPeriod } = useGetObjectsTotalRevenueInPeriod(objectId, onError, {
    datetime_from: month.datetime_from.format('YYYY-MM-DD HH:mm'),
    datetime_to: month.datetime_to.format('YYYY-MM-DD HH:mm')
  });

  const { data: latestAppointments } = useGetObjectsAllLatestAppointmentFinance(objectId, onError, {
    datetime_from: month.datetime_from.format('YYYY-MM-DD HH:mm'),
    datetime_to: month.datetime_to.format('YYYY-MM-DD HH:mm')
    // perPage: total
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <CustomDatePicker
          viewType="month"
          onChange={(datetime_from, datetime_to) => {
            setMonth({ datetime_from, datetime_to });
          }}
          disableNavigation
        />

        <Button variant="contained">
          <CSVLink
            data={latestAppointments?.data ?? []}
            headers={[
              { label: t('date'), key: 'date' },
              { label: t('user'), key: 'user.name' },
              { label: t('email'), key: 'user.email' },
              { label: t('phone'), key: 'user.phone' },
              { label: t('field'), key: 'court_sport' },
              { label: t('appointment'), key: 'time' },
              { label: t('price'), key: 'price' }
            ]}
            className="btn btn-primary"
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
            filename={`finance-report-${sportCenter.name}-${month.datetime_from.format(
              'MMMM-YYYY'
            )}.csv`}
            asyncOnClick={true}
          >
            {t('download_finance')}
          </CSVLink>
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <AnalyticsCard
          header=""
          content={`${totalRevenue?.total_revenue?.toLocaleString('sr-Latn-RS', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })} RSD`}
          percent={``}
          footerText={t('finances_overview.total_income_for_selected_object')}
          headerIcon={<HouseIcon fill="white" />}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <AnalyticsCard
          header=""
          content={`${totalRevenue?.appointments_from_app_revenue?.toLocaleString('sr-Latn-RS', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })} RSD`}
          percent={``}
          footerText={t('finances_overview.total_income_through_the_app')}
          headerIcon={<MobileIcon fill="white" />}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <AnalyticsCard
          header=""
          content={`${totalRevenue?.future_appointments_from_app_revenue?.toLocaleString(
            'sr-Latn-RS',
            {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          )} RSD`}
          percent={``}
          footerText={t('finances_overview.total_income_in_the_future')}
          headerIcon={<ClockIcon fill="white" />}
        />
      </Grid>

      <Grid item xs={12}>
        <ChartCard
          title={t('income')}
          count={`${
            totalRevenueInPeriod?.total_revenue?.toLocaleString('sr-Latn-RS', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) ?? 0
          } RSD`}
          subtitle={t('finance.total_income_this_month')}
        >
          <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
            <AreaChartSM
              data={totalRevenueInPeriod?.appointments_count_by_hour ?? []}
              tooltipValueText={t('appointments_count').toString()}
            />
          </Box>
        </ChartCard>
      </Grid>
    </Grid>
  );
};

export default SportCenterOverviewFinance;
