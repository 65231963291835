import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
//components
import { Box, Button, Grid } from '@mui/material';
import AnalyticsCard from '@components/AnalyticsCard/AnalyticsCard';
import ChartCard from '@components/ChartCard/ChartCard';
import DataCard from '@components/DataCard/DataCard';
import CustomDatePicker from '@components/CustomDatePicker/CustomDatePicker';
import BarChartCustom from '@components/Charts/BarChart/BarChartCustom';
import PieChartSM from '@components/Charts/PieChart/PieChart';
import AreaChartSM from '@components/Charts/AreaChart/AreaChart';
//hooks
import { useTranslation } from 'react-i18next';
//api
import {
  useAdminAppointmentsCount,
  useAdminCanceledAppointments,
  useAdminLastAppointments,
  useAdminMostVisitedDays,
  useAdminObjectUsage,
  useSportCenterAppointmentsCountByHour
} from '@api/queries/analytics/analytics';
//type
import {
  AdminCanceledAppointmentsAnalyticsType,
  AdminLastAppointmentsAnalyticsType
} from '@interfaces/analytics/analytics';
import { MRT_ColumnDef } from 'material-react-table';
import { DateView } from '@mui/x-date-pickers';
// recoil
import { useSetRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';
//icons
import { ClockIcon } from '@components/icons/icons';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
//helpers
import dayjs, { Dayjs } from 'dayjs';

const AdminAppointmentsAnalyticsPage = () => {
  const [viewTypeArea, setViewTypeArea] = useState<DateView>('day');
  const [date, setDate] = useState({
    datetime_start: dayjs().startOf('day'),
    datetime_end: dayjs().endOf('day')
  });

  const [select, setSelect] = useState<undefined | 'cms' | 'client'>();

  const { t } = useTranslation();

  const setPopup = useSetRecoilState(popupAtom);

  const params = useParams();
  const sportCenterId = params.sportCenterId ? +params.sportCenterId : 0;

  const onError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: err.name,
      variant: 'error'
    });
  };

  const { data: count } = useAdminAppointmentsCount(sportCenterId, select, onError);
  const { data: latestAppointments } = useAdminLastAppointments(sportCenterId, onError, {
    frontApp: select
  });
  const { data: canceledAppointments } = useAdminCanceledAppointments(sportCenterId, onError);
  const { data: objectUsage } = useAdminObjectUsage(sportCenterId, onError);
  const { data: totalAppointments } = useSportCenterAppointmentsCountByHour(
    onError,
    sportCenterId,
    {
      frontApp: select,
      datetime_from: date.datetime_start.format('YYYY-MM-DD HH:mm'),
      datetime_to: date.datetime_end.format('YYYY-MM-DD HH:mm')
    }
  );
  const { data: mostVisitedDays } = useAdminMostVisitedDays(sportCenterId, onError, {
    frontApp: select
  });

  const scheduledAppointmentsColumns = useMemo<MRT_ColumnDef<AdminLastAppointmentsAnalyticsType>[]>(
    () => [
      {
        accessorKey: 'user.name',
        header: t('user')
      },
      {
        accessorKey: 'time',
        header: t('time')
      },
      {
        accessorKey: 'date',
        header: t('date')
      },
      {
        accessorKey: 'court_sport',
        header: `${t('field')} - ${t('sport')}`
      },
      {
        accessorKey: 'reserved_from',
        header: t('channel')
      }
    ],
    []
  );

  const canceledAppointmentsColumns = useMemo<
    MRT_ColumnDef<AdminCanceledAppointmentsAnalyticsType>[]
  >(
    () => [
      {
        accessorKey: 'user.name',
        header: t('user')
      },
      {
        accessorKey: 'canceled_at',
        header: t('cancelation_time')
      },
      {
        accessorKey: 'appointment',
        header: t('appointment')
      },
      {
        accessorKey: 'court_sport',
        header: `${t('field')} - ${t('sport')}`
      },
      {
        accessorKey: 'canceled_by',
        header: t('canceled_from')
      }
    ],
    []
  );

  const handleDateChange = (datetime_start: Dayjs, datetime_end: Dayjs) => {
    setDate({
      datetime_start,
      datetime_end
    });
  };

  return (
    <Box px={3} py={2}>
      <Box sx={{ pb: 2, display: 'flex', gap: 2 }}>
        <Button
          onClick={() => setSelect(undefined)}
          variant="tab"
          color={select === undefined ? 'primary' : 'secondary'}
        >
          {t('all')}
        </Button>
        <Button
          variant="tab"
          color={select === 'client' ? 'primary' : 'secondary'}
          onClick={() => setSelect('client')}
        >
          {t('app')}
        </Button>
        <Button
          variant="tab"
          color={select === 'cms' ? 'primary' : 'secondary'}
          onClick={() => setSelect('cms')}
        >
          {t('admin_analytics.cms')}
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            header={''}
            content={count?.completed.toString() ?? '0'}
            percent={''}
            footerText={t('admin_analytics.appointments_played')}
            headerIcon={<CheckRoundedIcon sx={{ color: 'white' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            header={''}
            content={count?.upcoming.toString() ?? '0'}
            percent={''}
            footerText={t('admin_analytics.appointments_in_future')}
            headerIcon={<ClockIcon fill="white" />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            header={''}
            content={count?.canceled.toString() ?? '0'}
            percent={''}
            footerText={t('admin_analytics.appointments_canceled')}
            headerIcon={<CloseIcon sx={{ color: 'white' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            header={''}
            content={count?.unrealized.toString() ?? '0'}
            percent={''}
            footerText={t('admin_analytics.appointments_non_relaised')}
            headerIcon={<InfoOutlinedIcon sx={{ color: 'white' }} />}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1}>
        <Grid item xs={12}>
          <ChartCard
            title={
              t('admin_analytics.total_reservations') +
              '  ' +
              t(`admin_analytics.from_${select}`, '')
            }
            count={totalAppointments?.appointments_total_count ?? 0}
            subtitle={t('admin_analytics.total_reservations_for_period')}
            action={
              <CustomDatePicker
                viewType={viewTypeArea}
                value={dayjs().format('DD. MMM YYYY')}
                onChange={handleDateChange}
                maxDate={dayjs().format('DD. MMM YYYY')}
                withChangeView
                setViewType={setViewTypeArea}
              />
            }
          >
            <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
              <AreaChartSM
                data={totalAppointments?.appointments_count_by_hour ?? []}
                tooltipValueText={t('admin_analytics.reservations_number').toString()}
              />
            </Box>
          </ChartCard>
        </Grid>

        <Grid item xs={12} sm={12} md={6} xl={4}>
          <ChartCard
            title={t('admin_analytics.most_producted_days')}
            count={mostVisitedDays?.total ?? 0}
            subtitle={t('admin_analytics.total_appointments')}
          >
            <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
              <BarChartCustom data={mostVisitedDays?.data ?? []} yKey={'day'} multicolor />
            </Box>
          </ChartCard>
        </Grid>

        <Grid item xs={12} sm={12} md={6} xl={8}>
          <DataCard
            title={t('admin_analytics.last_reservated_appointments')}
            columns={scheduledAppointmentsColumns}
            data={latestAppointments?.data ?? []}
            showHeader
          />
        </Grid>

        {select === undefined && (
          <Grid item xs={12} sm={12} md={8}>
            <DataCard
              title={t('admin_analytics.last_canceled_appointments')}
              columns={canceledAppointmentsColumns}
              data={canceledAppointments?.data ?? []}
              showHeader
            />
          </Grid>
        )}
        {select === undefined && (
          <Grid item xs={12} sm={12} md={4}>
            <ChartCard
              title={t('admin_analytics.object_usage')}
              count={objectUsage?.total ?? 0}
              subtitle={t('admin_analytics.last_month')}
            >
              <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
                <PieChartSM
                  data={objectUsage?.data ?? []}
                  dataKey={'value'}
                  emptyValue="unbooked"
                  emptyValueKey="name"
                />
              </Box>
            </ChartCard>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AdminAppointmentsAnalyticsPage;
