//components
import { Card, CardContent, CardHeader, Button } from '@mui/material';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
//hooks
import { useTranslation } from 'react-i18next';
//icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

type DataCardProps<T extends Object> = {
  columns: MRT_ColumnDef<T>[];
  data: T[];
  removePadding?: boolean;
  title: string;
  subtitle?: string;
  removeShaddow?: boolean;
  showHeader?: boolean;
  withAction?: boolean;
  actionText?: string;
};

function DataCard<T extends Object>({
  columns,
  data,
  removePadding,
  title,
  subtitle,
  removeShaddow,
  showHeader,
  withAction,
  actionText
}: DataCardProps<T>) {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        px: 1,
        boxShadow: `${removeShaddow && 'none'}`,
        height: '100%'
      }}
    >
      <CardHeader
        title={title}
        subheader={subtitle}
        sx={{ paddingBottom: 0 }}
        action={
          withAction && (
            <Button endIcon={<NavigateNextIcon />}>
              {actionText ? actionText : t('view_all')}
            </Button>
          )
        }
      />
      <CardContent>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={false}
          enableSorting={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          muiTableBodyRowProps={{ hover: false }}
          defaultColumn={{
            minSize: 20,
            maxSize: 200,
            size: 100
          }}
          muiTablePaperProps={{
            sx: {
              border: 'none',
              boxShadow: 'none'
            }
          }}
          muiTableBodyCellProps={
            removePadding
              ? {
                  sx: {
                    padding: '5px'
                  }
                }
              : {}
          }
          muiTableHeadProps={{
            sx: { display: showHeader ? '' : 'none' }
          }}
          muiTableHeadRowProps={{
            sx: { backgroundColor: '#F3F4F6' }
          }}
        />
      </CardContent>
    </Card>
  );
}

export default DataCard;
