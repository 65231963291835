//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';
//interfaces
import { AxiosError, AxiosResponse } from 'axios';
import {
  PaginationResponseType,
  ObjectAppointmentsFinancesParamsType,
  ObjectTotalRevenueInPeriodType,
  ObjectTotalRevenuePerMethodType,
  ObjectTotalRevenueType,
  ResponseType,
  TotalRevenueType,
  ObjectAppointmentsFinancesType,
  ObjectTotalRevenueBySportParamsType,
  PaginationParamsType,
  ObjectBestRecurringAppontmentsType,
  ObjectBestCoachesType,
  ObjectTotalRevenueBySportType,
  DateRangeParamsType
} from '@interfaces/finance/finance';
//service
import {
  getObjectsAllAppointmentsFinance,
  getObjectsAppointmentsFinance,
  getObjectsBestCoachesFinance,
  getObjectsBestRecurringAppointmentsFinance,
  getObjectsTotalRevenue,
  getObjectsTotalRevenueInPeriod,
  getObjectsTotalRevenuePerMethod,
  getTotalRevenue,
  getTotalRevenueBySport
} from '@services/finance/finance';
import { MetaType } from '@interfaces/apiResponse';

export const useGetTotalRevenue = (
  onError: (err: AxiosResponse | undefined) => void
): UseQueryResult<TotalRevenueType, AxiosError> =>
  useQuery(['totalRevenue'], () => getTotalRevenue(), {
    select: res => res.data.data,
    onError: err => onError(err.response)
  });

export const useGetObjectsTotalRevenue = (
  id: number,
  onError: (err: AxiosResponse | undefined) => void
): UseQueryResult<ObjectTotalRevenueType, AxiosError> =>
  useQuery(['ObjectTotalRevenue', id], () => getObjectsTotalRevenue(id), {
    select: res => res.data.data,
    onError: err => onError(err.response),
    enabled: !!id
  });

export const useGetObjectsTotalRevenueInPeriod = (
  id: number,
  onError: (err: AxiosResponse | undefined) => void,
  params?: ObjectTotalRevenueBySportParamsType
): UseQueryResult<ObjectTotalRevenueInPeriodType, AxiosError> =>
  useQuery(
    ['ObjectTotalRevenueInPeriod', id, params],
    () => getObjectsTotalRevenueInPeriod(id, params),
    {
      select: res => res.data.data,
      onError: err => onError(err.response),
      enabled: !!id
    }
  );

export const useGetObjectsTotalRevenuePerMethod = (
  id: number,
  onError: (err: AxiosResponse | undefined) => void
): UseQueryResult<ObjectTotalRevenuePerMethodType, AxiosError> =>
  useQuery(['ObjectTotalRevenuePerMonth', id], () => getObjectsTotalRevenuePerMethod(id), {
    select: res => res.data.data,
    onError: err => onError(err.response),
    enabled: !!id
  });

export const useGetObjectsAppointmentFinance = (
  id: number,
  onError: (err: AxiosResponse | undefined) => void,
  params?: ObjectAppointmentsFinancesParamsType
): UseQueryResult<{ data: ObjectAppointmentsFinancesType; meta: MetaType }, AxiosError> =>
  useQuery(
    ['ObjectAppointmentFinance', id, params],
    () => getObjectsAppointmentsFinance(id, params),
    {
      select: res => res.data,
      onError: err => onError(err.response),
      enabled: !!id
    }
  );

export const useGetObjectsSportsRevenue = (
  id: number,
  onError: (err: AxiosResponse | undefined) => void,
  params?: ObjectTotalRevenueBySportParamsType
): UseQueryResult<{ data: ObjectTotalRevenueBySportType[]; meta: MetaType }, AxiosError> =>
  useQuery(['ObjectTotalRevenueBySport', id, params], () => getTotalRevenueBySport(id, params), {
    select: res => res.data,
    onError: err => onError(err.response),
    enabled: !!id
  });

export const useGetObjectsBestRecurringAppointmentFinance = (
  id: number,
  onError: (err: AxiosResponse | undefined) => void,
  params?: PaginationParamsType
): UseQueryResult<{ data: ObjectBestRecurringAppontmentsType[]; meta: MetaType }, AxiosError> =>
  useQuery(
    ['ObjectBestRecurringAppointmentFinance', id, params],
    () => getObjectsBestRecurringAppointmentsFinance(id, params),
    {
      select: res => res.data,
      onError: err => onError(err.response),
      enabled: !!id
    }
  );

export const useGetObjectsBestCoachesFinance = (
  id: number,
  onError: (err: AxiosResponse | undefined) => void,
  params?: PaginationParamsType
): UseQueryResult<{ data: ObjectBestCoachesType[]; meta: MetaType }, AxiosError> =>
  useQuery(
    ['ObjectBestCoachesFinance', id, params],
    () => getObjectsBestCoachesFinance(id, params),
    {
      select: res => res.data,
      onError: err => onError(err.response),
      enabled: !!id
    }
  );
  export const useGetObjectsAllLatestAppointmentFinance = (
    id: number,
    onError: (err: AxiosResponse | undefined) => void,
    params?: DateRangeParamsType
  ): UseQueryResult<{ data: ObjectAppointmentsFinancesType; meta: MetaType }, AxiosError> =>
    useQuery(
      ['ObjectAllAppointmentFinance', id, params],
      () => getObjectsAllAppointmentsFinance(id, params),
      {
        select: res => res.data,
        onError: err => onError(err.response),
        enabled: !!id
      }
    );