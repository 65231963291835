//interfaces
import {
  AnalyticsCountsResponseType,
  AppointmentsCountByHourResponseType,
  CourtCountBySportResponseType,
  LatestAppointmentsResponseType,
  LatestUsersResponseType,
  SportCenterMostActiveUsersResponseType,
  SportCenterAnalyticCountResponseType,
  ObjectAnalyticsCountsTypes,
  ObjectAppointmentsCountType,
  ObjectsMostPopularAnalyticsType,
  ObjectAnalyticsCriteriaType,
  ObjectAnalyticsFilterQueryParamsType,
  ObjectAnalyticsFilterResponseType,
  UsersCountType,
  LatestUsersCountType,
  UsersByGendersType,
  AdminAppointmentsCountType,
  AdminLastAppointmentsAnalyticsType,
  AdminCanceledAppointmentsAnalyticsType,
  AdminObjectUsageAnalyticsResponseType,
  AdminUsersCountType,
  AdminUsersPercentageType,
  AdminUsersFrequencyType,
  SportCenterAppointmentsCountByHourType,
  AdminMostVisitedDaysType
} from '@interfaces/analytics/analytics';
import { MetaType } from '@interfaces/apiResponse';
//services
import {
  getAdminAppointmentsCounts,
  getAdminCanceledAppointments,
  getAdminLastAppointments,
  getAdminMostVisitedDays,
  getAdminObjectUsage,
  getAdminUsersCounts,
  getAdminUsersFrequency,
  getAdminUsersPercentage,
  getAnalyticsCounts,
  getAppointmentsCountByHour,
  getCourtsCountBySport,
  getLatestUsers,
  getLatestUsersCount,
  getLatestappointments,
  getObjectAnalyticsCounts,
  getObjectAppointmentsCount,
  getObjectFilterAnalytics,
  getObjectGraph,
  getObjectMostPopular,
  getSportCenterAnalyticsCount,
  getSportCenterAppointmentsCountByHour,
  getSportCenterMostActiveUsers,
  getUsersByGenders,
  getUsersCount
} from '@services/analytics/analytics';
//react-query
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useAnalyticsCounts = (
  onError: (err: Error) => void
): UseQueryResult<AnalyticsCountsResponseType, Error> =>
  useQuery(['superadminAnalyticsCounts'], () => getAnalyticsCounts(), {
    onError: err => onError(err)
  });

export const useLatestUsers = (
  onError: (err: Error) => void,
  limit?: number
): UseQueryResult<LatestUsersResponseType, Error> =>
  useQuery(['superadminLatestUsers', limit], () => getLatestUsers(limit), {
    onError: err => onError(err)
  });

export const useAppointmentsCountByHour = (
  onError: (err: Error) => void,
  datetime_from?: string,
  datetime_to?: string
): UseQueryResult<AppointmentsCountByHourResponseType, Error> =>
  useQuery(
    ['superadminAppointmentsCountByHour', datetime_from, datetime_to],
    () => getAppointmentsCountByHour(datetime_from, datetime_to),
    {
      onError: err => onError(err)
    }
  );

export const useLatestAppointments = (
  onError: (err: Error) => void,
  limit?: number
): UseQueryResult<LatestAppointmentsResponseType, Error> =>
  useQuery(['superadminLatestAppointments', limit], () => getLatestappointments(limit), {
    onError: err => onError(err)
  });

export const useCourtsCountBySports = (
  onError: (err: Error) => void
): UseQueryResult<CourtCountBySportResponseType, Error> =>
  useQuery(['superadminCourtsCountBySports'], () => getCourtsCountBySport(), {
    onError: err => onError(err)
  });

export const useSportCenterAnalyticsCount = (
  sportCenter: number,
  onError: (err: Error) => void
): UseQueryResult<SportCenterAnalyticCountResponseType, Error> =>
  useQuery(
    ['adminSportCenterAnalyticsCount', sportCenter],
    () => getSportCenterAnalyticsCount(sportCenter),
    {
      onError: err => onError(err)
    }
  );

export const useSportCenterAppointmentsCountByHour = (
  onError: (err: Error) => void,
  sportCenter: number,
  params?: {
    frontApp: 'cms' | 'client' | undefined;
    datetime_from?: string;
    datetime_to?: string;
  }
): UseQueryResult<SportCenterAppointmentsCountByHourType, Error> =>
  useQuery(
    ['adminSportCenterAppointmentsCountByHour', sportCenter, params],
    () => getSportCenterAppointmentsCountByHour(sportCenter, params),
    {
      onError: err => onError(err),
      select: data => data.data.data
    }
  );

export const useSportCenterMostActiveUsers = (
  onError: (err: Error) => void,
  sportCenter: number,
  params?: {
    limit?: number;
    with_favorite_sport?: boolean;
    with_channel?: boolean;
  }
): UseQueryResult<SportCenterMostActiveUsersResponseType, Error> =>
  useQuery(
    ['adminSportCenterMostActiveUsers', sportCenter, params],
    () => getSportCenterMostActiveUsers(sportCenter, params),
    {
      onError: err => onError(err)
    }
  );

export const useObjectAnalyticsCounts = (
  onError: (err: Error) => void
): UseQueryResult<ObjectAnalyticsCountsTypes, Error> =>
  useQuery(['superadminObjectAnalyticsCounts'], () => getObjectAnalyticsCounts(), {
    onError: err => onError(err),
    select: data => data.data.data
  });

export const useObjectAppointmentsCount = (
  onError: (err: Error) => void
): UseQueryResult<ObjectAppointmentsCountType, Error> =>
  useQuery(['superadminObjectAppointmentsCounts'], () => getObjectAppointmentsCount(), {
    onError: err => onError(err),
    select: data => data.data.data
  });
export const useObjectMostPopularCounts = (
  onError: (err: Error) => void
): UseQueryResult<ObjectsMostPopularAnalyticsType[], Error> =>
  useQuery(['superadminObjectMostPopularCounts'], () => getObjectMostPopular(), {
    onError: err => onError(err),
    select: data => data.data.data
  });
export const useObjectGraph = (
  onError: (err: Error) => void
): UseQueryResult<ObjectAnalyticsCriteriaType[], Error> =>
  useQuery(['superadminObjectGraphCriteria'], () => getObjectGraph(), {
    onError: err => onError(err),
    select: data => data.data.data
  });
export const useObjectFilterAnalytics = (
  params: ObjectAnalyticsFilterQueryParamsType,
  onError: (err: Error) => void
): UseQueryResult<ObjectAnalyticsFilterResponseType, Error> =>
  useQuery(['superadminObjectFilterAnalytics', params], () => getObjectFilterAnalytics(params), {
    onError: err => onError(err),
    select: data => data.data.data
  });

export const useUsersAnalyticsCount = (
  onError: (err: Error) => void
): UseQueryResult<UsersCountType, Error> =>
  useQuery(['superadminUsersAnalyticsCount'], () => getUsersCount(), {
    onError: err => onError(err),
    select: data => data.data.data
  });

export const useLatestUsersAnalyticsCount = (
  onError: (err: Error) => void,
  params?: {
    datetime_from: string;
    datetime_to: string;
  }
): UseQueryResult<LatestUsersCountType, Error> =>
  useQuery(['superadminLatestUsersAnalyticsCount', params], () => getLatestUsersCount(params), {
    onError: err => onError(err),
    select: data => data.data.data
  });

export const useUsersByGenders = (
  onError: (err: Error) => void
): UseQueryResult<UsersByGendersType, Error> =>
  useQuery(['superadminUsersByAnalyticsCount'], () => getUsersByGenders(), {
    onError: err => onError(err),
    select: data => data.data.data
  });

// admin appointments
export const useAdminAppointmentsCount = (
  id: number,
  frontApp: 'cms' | 'client' | undefined,
  onError: (err: Error) => void
): UseQueryResult<AdminAppointmentsCountType, Error> =>
  useQuery(
    ['adminAppointmentsCounts', id, frontApp],
    () => getAdminAppointmentsCounts(id, frontApp),
    {
      onError: err => onError(err),
      select: data => data.data.data,
      enabled: !!id
    }
  );

export const useAdminLastAppointments = (
  id: number,
  onError: (err: Error) => void,
  params?: {
    frontApp?: 'cms' | 'client' | undefined;
    page?: number;
    perPage?: number;
  }
): UseQueryResult<{ data: AdminLastAppointmentsAnalyticsType[]; meta: MetaType }, Error> =>
  useQuery(['adminLastAppointments', id, params], () => getAdminLastAppointments(id, params), {
    onError: err => onError(err),
    select: data => data.data
    // enabled: false
  });

export const useAdminCanceledAppointments = (
  id: number,
  onError: (err: Error) => void,
  params?: {
    page: number;
    perPage: number;
  }
): UseQueryResult<{ data: AdminCanceledAppointmentsAnalyticsType[]; meta: MetaType }, Error> =>
  useQuery(
    ['adminCanceledAppointments', id, params],
    () => getAdminCanceledAppointments(id, params),
    {
      onError: err => onError(err),
      select: data => data.data
      // enabled: false
    }
  );

export const useAdminObjectUsage = (
  id: number,
  onError: (err: Error) => void,
  params?: {
    datetime_from: string;
    datetime_to: string;
  }
): UseQueryResult<AdminObjectUsageAnalyticsResponseType, Error> =>
  useQuery(['adminObjectUsage', id, params], () => getAdminObjectUsage(id, params), {
    onError: err => onError(err),
    select: data => data.data
    // enabled: false
  });

// admin users

export const useAdminUsersCount = (
  id: number,
  onError: (err: Error) => void,
  params?: {
    datetime_from: string;
    datetime_to: string;
  }
): UseQueryResult<AdminUsersCountType, Error> =>
  useQuery(['adminUsersCounts', id, params], () => getAdminUsersCounts(id, params), {
    onError: err => onError(err),
    select: data => data.data.data,
    enabled: !!id
  });

export const useAdminUsersPercentage = (
  id: number,
  onError: (err: Error) => void
): UseQueryResult<AdminUsersPercentageType, Error> =>
  useQuery(['adminUsersPercentages', id], () => getAdminUsersPercentage(id), {
    onError: err => onError(err),
    select: data => data.data.data,
    enabled: !!id
  });

export const useAdminUsersFrequency = (
  id: number,
  onError: (err: Error) => void,
  params?: {
    datetime_from: string;
    datetime_to: string;
  }
): UseQueryResult<AdminUsersFrequencyType, Error> =>
  useQuery(['adminUsersFrequency', id, params], () => getAdminUsersFrequency(id, params), {
    onError: err => onError(err),
    select: data => data.data.data,
    enabled: !!id
  });

export const useAdminMostVisitedDays = (
  id: number,
  onError: (err: Error) => void,
  params?: {
    frontApp: 'cms' | 'client' | undefined;
    datetime_from?: string;
    datetime_to?: string;
  }
): UseQueryResult<AdminMostVisitedDaysType, Error> =>
  useQuery(['adminMostVisitedDays', id, params], () => getAdminMostVisitedDays(id, params), {
    onError: err => onError(err),
    select: data => data.data.data,
    enabled: !!id
  });
