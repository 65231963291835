import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
//components
import { Box, Grid, ListItem } from '@mui/material';
import AnalyticsCard from '@components/AnalyticsCard/AnalyticsCard';
import ChartCard from '@components/ChartCard/ChartCard';
import DataCard from '@components/DataCard/DataCard';
import CustomDatePicker from '@components/CustomDatePicker/CustomDatePicker';
import PieChartSM from '@components/Charts/PieChart/PieChart';
import AreaChartSM from '@components/Charts/AreaChart/AreaChart';
//hooks
import { useTranslation } from 'react-i18next';
//api
import {
  useAdminUsersCount,
  useAdminUsersFrequency,
  useAdminUsersPercentage,
  useSportCenterMostActiveUsers
} from '@api/queries/analytics/analytics';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { DateView } from '@mui/x-date-pickers';
// recoil
import { useSetRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';
//icons
import { ClockIcon } from '@components/icons/icons';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
//helpers
import dayjs, { Dayjs } from 'dayjs';

const AdminUsersAnalyticsPage = () => {
  const [viewTypeArea, setViewTypeArea] = useState<DateView>('day');
  const [date, setDate] = useState({
    datetime_start: dayjs().startOf('day'),
    datetime_end: dayjs().endOf('day')
  });

  const { t } = useTranslation();

  const setPopup = useSetRecoilState(popupAtom);

  const params = useParams();
  const sportCenterId = params.sportCenterId ? +params.sportCenterId : 0;

  const onError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: err.name,
      variant: 'error'
    });
  };

  const { data: count } = useAdminUsersCount(sportCenterId, onError);
  const { data: usersFrequency } = useAdminUsersFrequency(sportCenterId, onError, {
    datetime_from: date.datetime_start.format('YYYY-MM-DD HH:mm'),
    datetime_to: date.datetime_end.format('YYYY-MM-DD HH:mm')
  });
  const { data: usersPercentage } = useAdminUsersPercentage(sportCenterId, onError);
  const { data: mostActiveUsers } = useSportCenterMostActiveUsers(onError, sportCenterId, {
    with_channel: true
  });

  const activeUsersColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('user')
      },
      {
        accessorKey: 'channel',
        header: t('admin_analytics.app_type')
      },
      {
        accessorKey: 'appointments_count',
        header: t('appointments_count')
      },

      {
        accessorKey: 'appointments_sum_price',
        header: t('total'),
        Cell: ({ row }) => <ListItem>{row.original.appointments_sum_price} RSD</ListItem>
      }
    ],
    []
  );

  const handleDateChange = (datetime_start: Dayjs, datetime_end: Dayjs) => {
    setDate({
      datetime_start,
      datetime_end
    });
  };

  return (
    <Box px={3} py={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            header={''}
            content={count?.members.toString() ?? '0'}
            percent={''}
            footerText={t('admin_analytics.total_users')}
            headerIcon={<CheckRoundedIcon sx={{ color: 'white' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            header={''}
            content={count?.new_members.toString() ?? '0'}
            percent={''}
            footerText={t('admin_analytics.total_new_users')}
            headerIcon={<ClockIcon fill="white" />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            header={''}
            content={count?.app_members.toString() ?? '0'}
            percent={''}
            footerText={t('admin_analytics.total_app_users')}
            headerIcon={<CloseIcon sx={{ color: 'white' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            header={''}
            content={count?.recurring_members.toString() ?? '0'}
            percent={''}
            footerText={t('admin_analytics.total_back_users')}
            headerIcon={<InfoOutlinedIcon sx={{ color: 'white' }} />}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} sm={12} md={4}>
          <ChartCard
            title={t('admin_analytics.users_division')}
            subtitle={t('admin_analytics.users_percents')}
            count={usersPercentage?.total ?? 0}
          >
            <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
              <PieChartSM data={usersPercentage?.data ?? []} dataKey={'value'} isEmpty />
            </Box>
          </ChartCard>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <DataCard
            title={t('admin_analytics.most_active_users')}
            columns={activeUsersColumns}
            data={mostActiveUsers?.data.data || []}
            showHeader
          />
        </Grid>

        <Grid item xs={12}>
          <ChartCard
            title={t('admin_analytics.total_users')}
            count={usersFrequency?.users_total_count ?? 0}
            subtitle={t('admin_analytics.total_users_for_period')}
            action={
              <CustomDatePicker
                viewType={viewTypeArea}
                value={dayjs().format('DD. MMM YYYY')}
                onChange={handleDateChange}
                maxDate={dayjs().format('DD. MMM YYYY')}
                setViewType={setViewTypeArea}
                withChangeView
              />
            }
          >
            <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
              <AreaChartSM
                data={usersFrequency?.appointments_count_in_period ?? []}
                tooltipValueText={t('admin_analytics.users_number').toString()}
              />
            </Box>
          </ChartCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminUsersAnalyticsPage;
