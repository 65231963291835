// components

import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
// styles
import '@styles/components/charts.scss';
// helpers
import { randomColorsForGraphs as COLORS } from '@helpers/utility';
import { useTranslation } from 'react-i18next';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props: any) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent, payload } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      key={payload.name}
      fontFamily="Inter"
      fontSize={12}
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

type PieChartProps<T> = {
  data: T[];
  dataKey: keyof T;
  isEmpty?: boolean;
  emptyValue?: string;
  emptyValueKey?: keyof T;
};

const PieChartSM = <T extends {}>({
  data,
  dataKey,
  isEmpty,
  emptyValue,
  emptyValueKey
}: PieChartProps<T>): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ResponsiveContainer width="100%" height={270}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={isEmpty ? 40 : 0}
          labelLine={false}
          label={renderCustomizedLabel}
          dataKey={dataKey.toString()}
        >
          {data.map((entry, index) => {
            if (emptyValueKey && emptyValue)
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    entry[emptyValueKey] === emptyValue ? '#cbcbcb' : COLORS[index % COLORS.length]
                  }
                  name={
                    entry[emptyValueKey] === emptyValue
                      ? t('free').toString()
                      : `${entry[emptyValueKey]}`
                  }
                />
              );

            return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />;
          })}
        </Pie>
        <Tooltip itemStyle={{ fontFamily: 'Inter' }} />
        <Legend
          iconType="circle"
          payload={
            emptyValueKey && emptyValue
              ? (data
                  .map((item, index) => {
                    if (item[emptyValueKey] === emptyValue) return null;
                    return {
                      id: `${item[emptyValueKey]}`,
                      value: `${item[emptyValueKey]} (${item[dataKey]}%)`,
                      color: COLORS[index % COLORS.length]
                    };
                  })
                  .filter(item => item !== null) as Payload[])
              : undefined
          }
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartSM;
