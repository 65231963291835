//interface

import {
  PaginationResponseType,
  ObjectAppointmentsFinancesParamsType,
  ObjectTotalRevenueInPeriodType,
  ObjectTotalRevenuePerMethodType,
  ObjectTotalRevenueType,
  ResponseType,
  TotalRevenueType,
  ObjectAppointmentsFinancesType,
  ObjectTotalRevenueBySportParamsType,
  PaginationParamsType,
  ObjectBestRecurringAppontmentsType,
  ObjectBestCoachesType,
  DateRangeParamsType,
  ObjectTotalRevenueBySportType
} from '@interfaces/finance/finance';

//axios instance
import axiosInstance from '@services/config';

export const getTotalRevenue = (): Promise<ResponseType<TotalRevenueType>> =>
  axiosInstance.get(`/finances/total-revenue`);

export const getObjectsTotalRevenue = (id: number): Promise<ResponseType<ObjectTotalRevenueType>> =>
  axiosInstance.get(`/finances/by-sport-center/${id}/total-revenue`);

export const getObjectsTotalRevenueInPeriod = (
  id: number,
  params?: ObjectTotalRevenueBySportParamsType
): Promise<ResponseType<ObjectTotalRevenueInPeriodType>> =>
  axiosInstance.get(`/finances/by-sport-center/${id}/total-revenue-in-period`, {
    params
  });

export const getObjectsTotalRevenuePerMethod = (
  id: number
): Promise<ResponseType<ObjectTotalRevenuePerMethodType>> =>
  axiosInstance.get(`/finances/by-sport-center/${id}/total-revenue-per-method`);

export const getObjectsAppointmentsFinance = (
  id: number,
  params?: ObjectAppointmentsFinancesParamsType
): Promise<PaginationResponseType<ObjectAppointmentsFinancesType>> =>
  axiosInstance.get(`/finances/by-sport-center/${id}/latest-appointments`, {
    params: { ...params }
  });

export const getTotalRevenueBySport = (
  id: number,
  params?: ObjectTotalRevenueBySportParamsType
): Promise<PaginationResponseType<ObjectTotalRevenueBySportType[]>> =>
  axiosInstance.get(`/finances/by-sport-center/${id}/total-revenue-by-sport`, { params });

export const getObjectsBestRecurringAppointmentsFinance = (
  id: number,
  params?: PaginationParamsType
): Promise<PaginationResponseType<ObjectBestRecurringAppontmentsType[]>> =>
  axiosInstance.get(`/finances/by-sport-center/${id}/best-recurring-appointments`, { params });

export const getObjectsBestCoachesFinance = (
  id: number,
  params?: PaginationParamsType
): Promise<PaginationResponseType<ObjectBestCoachesType[]>> =>
  axiosInstance.get(`/finances/by-sport-center/${id}/best-coaches`, { params });

  export const getObjectsAllAppointmentsFinance = (
    id: number,
    params?: DateRangeParamsType
  ): Promise<PaginationResponseType<ObjectAppointmentsFinancesType>> =>
    axiosInstance.get(`/finances/by-sport-center/${id}/all-latest-appointments`, {
      params: { ...params }
    });