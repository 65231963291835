import React, { useMemo, useRef, useState } from 'react';
//components
import { Box, Button, Dialog, Grid } from '@mui/material';

import ChartCard from '@components/ChartCard/ChartCard';
import DataCard from '@components/DataCard/DataCard';

import BarChartCustom from '@components/Charts/BarChart/BarChartCustom';
import PieChartSM from '@components/Charts/PieChart/PieChart';

//hooks
import { useTranslation } from 'react-i18next';
//api
//type
import { MRT_ColumnDef } from 'material-react-table';

// recoil
import { useSetRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';
//icons
import { NewUsersIcon, PhoneIcon, RevertIcon, UsersIcon } from '@components/icons/icons';

import AnalyticsStatementPDF from '@pages/Analitics/AnalyticStatementPDF/AnalyticsStatementPDF';
import { useOutletContext, useParams } from 'react-router-dom';
import { SportCenterResponse, SportCenterResponseType } from '@interfaces/SportCenters/SportCenter';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import AnalyticsCard from '@components/AnalyticsCard/AnalyticsCard';
import {
  useAdminCanceledAppointments,
  useAdminLastAppointments,
  useAdminMostVisitedDays,
  useAdminObjectUsage,
  useAdminUsersCount
} from '@api/queries/analytics/analytics';

const SportCenterOverviewAnalitics = (): JSX.Element => {
  const { t } = useTranslation();
  const setPopup = useSetRecoilState(popupAtom);

  const [pdf, setPdf] = useState<boolean>(false);

  const onError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: err.name,
      variant: 'error'
    });
  };

  const onSuccess = (res: SportCenterResponse | undefined) => {};

  const params = useParams();
  const objectId = params.objectId ? +params.objectId : 0;

  const [data] = useOutletContext<[SportCenterResponseType]>();

  const { data: analyticsCount } = useAdminUsersCount(objectId, onError);

  const { data: latestAppointments } = useAdminLastAppointments(objectId, onError);
  const { data: canceledAppointments } = useAdminCanceledAppointments(objectId, onError);
  const { data: totalAppointments } = useAdminMostVisitedDays(objectId, onError);
  const { data: objectUsage } = useAdminObjectUsage(objectId, onError);

  const scheduledAppointmentsColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'user.name',
        header: t('user')
      },
      {
        accessorKey: 'time',
        header: t('time')
      },
      {
        accessorKey: 'date',
        header: t('date')
      },

      {
        accessorKey: 'court_sport',
        header: `${t('sport')}`
      }
    ],
    []
  );
  const canceledAppointmentsColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'user.name',
        header: t('user')
      },
      {
        accessorKey: 'canceled_at',
        header: t('cancelation_time')
      },
      {
        accessorKey: 'appointment',
        header: t('appointment')
      },
      {
        accessorKey: 'court_sport',
        header: `${t('sport')}`
      },
      {
        accessorKey: 'canceled_by',
        header: t('canceled_from')
      }
    ],
    []
  );

  const downloadChart = async () => {
    setPdf(true);
  };

  return (
    <Box px={3} pb={2}>
      <Box sx={{ display: 'flex', width: '100%', pb: 1, justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={() => downloadChart()}>
          {t('download')}
        </Button>
      </Box>
      <Dialog open={pdf} onClose={() => setPdf(false)} fullScreen>
        <PDFViewer style={{ height: '100%' }}>
          <AnalyticsStatementPDF
            objectName={data?.name ?? ''}
            pieData={objectUsage ?? { data: [], total: 0 }}
            barData={totalAppointments ?? { data: [], total: 0 }}
            lastAppointments={latestAppointments?.data ?? []}
            canceledAppointments={canceledAppointments?.data ?? []}
            counts={{
              totalUsers: analyticsCount?.members ?? 0,
              newUsers: analyticsCount?.new_members ?? 0,
              appUsers: analyticsCount?.app_members ?? 0,
              backUsers: analyticsCount?.recurring_members ?? 0
            }}
          />
        </PDFViewer>
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} p={2} gap={2}>
          <Button variant="outlined" onClick={() => setPdf(false)} sx={{ width: 'fit-content' }}>
            {t('back')}
          </Button>
          <Button variant="contained" sx={{ height: 'fit-content' }}>
            <PDFDownloadLink
              style={{ textDecoration: 'none', color: 'inherit' }}
              document={
                <AnalyticsStatementPDF
                  objectName={data?.name ?? ''}
                  pieData={objectUsage ?? { data: [], total: 0 }}
                  barData={totalAppointments ?? { data: [], total: 0 }}
                  lastAppointments={latestAppointments?.data ?? []}
                  canceledAppointments={canceledAppointments?.data ?? []}
                  counts={{
                    totalUsers: analyticsCount?.members ?? 0,
                    newUsers: analyticsCount?.new_members ?? 0,
                    appUsers: analyticsCount?.app_members ?? 0,
                    backUsers: analyticsCount?.recurring_members ?? 0
                  }}
                />
              }
              fileName={`${t('admin_analytics.analytics_report')} ${data?.name}.pdf`}
            >
              {t('download')}
            </PDFDownloadLink>
          </Button>
        </Box>
      </Dialog>

      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AnalyticsCard
              header={''}
              content={analyticsCount?.members.toString() ?? '0'}
              percent={''}
              footerText={t('admin_analytics.total_users')}
              headerIcon={<UsersIcon fill="white" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AnalyticsCard
              header={''}
              content={analyticsCount?.new_members.toString() ?? '0'}
              percent={''}
              footerText={t('admin_analytics.total_new_users')}
              headerIcon={<NewUsersIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AnalyticsCard
              header={''}
              content={analyticsCount?.app_members.toString() ?? '0'}
              percent={''}
              footerText={t('admin_analytics.total_app_users')}
              headerIcon={<PhoneIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AnalyticsCard
              header={''}
              content={analyticsCount?.recurring_members.toString() ?? '0'}
              percent={''}
              footerText={t('admin_analytics.total_back_users')}
              headerIcon={<RevertIcon />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={12} md={6} xl={4}>
            <ChartCard
              title={t('admin_analytics.appointments_played')}
              count={totalAppointments?.total ?? 0}
              subtitle={t('superadmin_analytics.appointments_number_this_month')}
            >
              <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
                <BarChartCustom data={totalAppointments?.data ?? []} yKey={'day'} multicolor />
              </Box>
            </ChartCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={8}>
            <DataCard
              title={t('admin_analytics.last_reservated_appointments')}
              columns={scheduledAppointmentsColumns}
              data={latestAppointments?.data ?? []}
              showHeader
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <DataCard
              title={t('admin_analytics.last_canceled_appointments')}
              columns={canceledAppointmentsColumns}
              data={canceledAppointments?.data ?? []}
              showHeader
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <ChartCard
              title={t('admin_analytics.object_usage')}
              count={`${objectUsage?.total ?? 0} %`}
              subtitle={t('superadmin_analytics.appointments_number_this_month')}
            >
              <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
                <PieChartSM
                  data={objectUsage?.data ?? []}
                  dataKey={'value'}
                  emptyValue="unbooked"
                  emptyValueKey="name"
                />
              </Box>
            </ChartCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SportCenterOverviewAnalitics;
