import { ReactNode } from 'react';
//components
import { Card, CardContent, CardHeader, SxProps } from '@mui/material';

type CardWrapperProps = {
  children: ReactNode;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  showMore?: ReactNode;
  isForm?: boolean;
  removeShadow?: boolean;
  sx?: SxProps;
};

const CardWrapper = ({
  title,
  subtitle,
  children,
  isForm,
  showMore,
  removeShadow,
  sx
}: CardWrapperProps) => (
  <Card
    sx={
      sx
        ? sx
        : { px: isForm ? { md: 15, xs: 2 } : '', m: 2, boxShadow: `${removeShadow && 'none'}` }
    }
  >
    <CardHeader
      title={title}
      subheader={subtitle}
      action={showMore && showMore}
      titleTypographyProps={{ fontWeight: 'bold' }}
    />
    <CardContent>{children}</CardContent>
  </Card>
);

export default CardWrapper;
