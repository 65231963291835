//router
import { Outlet } from 'react-router-dom';
//layout
import SportCenterOverviewLayout from '@layout/SportCenterOverviewLayout/SportCenterOverviewLayout';
//pages
import UsersPage from '@pages/Users/AllUsers/Users';
import AppUsersPage from '@pages/Users/AppUsers/AppUsers';
import OwnersPage from '@pages/Users/Owners/Owners';
import SuperUsersPage from '@pages/Users/SuperUsers/SuperUsers';
import ManagersPage from '@pages/Users/Managers/Managers';
// import BusinessUsersPage from '@pages/Users/BusinessUsers/BusinessUsers';
import SportCenters from '@pages/SportsCenters/SportCenters';
import SportCenterOverview from '@pages/SportsCenters/SportCenterOverview/SportCenterOverview';
import EmployeesPage from '@pages/Cooperations/Employees/Employees';
import CreateEmployee from '@pages/Cooperations/Employees/Create/CreateEmployee';
import SingleEmployee from '@pages/Cooperations/Employees/SingleEmployee/SingleEmployee';
import LanguageSettingsPage from '@pages/Admin/Settings/LanguageSettingsPage/LanguageSettingsPage';
import SettingsPage from '@pages/Settings/SettingsPage';
import ChangePasswordPage from '@pages/Settings/ChangePasswordPage/ChangePasswordPage';
import SportSettingsPage from '@pages/Settings/SportSettingsPage/SportSettingsPage';
import CreateSportPage from '@pages/Settings/SportSettingsPage/CreateSportPage/CreateSportPage';
import CreateRolePage from '@pages/Settings/RoleSettingsPage/CreateRolePage/CreateRolePage';
import RoleSettingsPage from '@pages/Settings/RoleSettingsPage/RoleSettingsPage';
import { SingleUserPage } from '@pages/Users/SingleUser/SingleUserPage';
import SuperUserOverview from '@pages/Users/SuperUsers/SuperUserOverview';
import ComingSoonPage from '@pages/ComingSoon/ComingSoonPage';
//components
import RequireAuth from '@components/Auth/RequireAuth';
//containers
import CreateSportCenter from '@containers/sportCentersStepper/CreateSportCenterStepper';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';
// images
import dashboardImage from '@src/assets/images/comingSoon/dashboard.png';
import bussinessUsersImage from '@src/assets/images/comingSoon/bussinessUsers.png';
import objectAnalyticsImage from '@src/assets/images/comingSoon/objectAnalytics.png';
import objectFinanceImage from '@src/assets/images/comingSoon/financeObject.png';
import loginImage from '@src/assets/images/loginImage.png';
import AnalyticsPage from '@pages/Analytics/AnalyticsPage';
import { FinancesPage } from '@pages/Finances/FinancesPage';
import SportCentersAnalytics from '@pages/Analytics/SportsCentersAnalytics/SportCentersAnalytics';
import UserAnalyticsPage from '@pages/Analytics/UserAnalyticsPage/UserAnalyticsPage';
import SportCenterOverviewFinance from '@pages/SportsCenters/SportCenterOverviewFinance/SportCenterOverviewFinance';
import SportCenterOverviewAnalitics from '@pages/SportsCenters/SportCenterOverviewAnalitics/SportCenterOverviewAnalitics';

export const superadminRoutes = {
  element: <RequireAuth allowedPermissions={[]} />,
  children: [
    {
      path: '/dashboard',
      element: <RequireAuth allowedPermissions={[]} />,
      children: [
        {
          path: '',
          element: <ComingSoonPage imageURL={dashboardImage} />
        }
      ]
    },

    {
      path: 'objects',
      element: <RequireAuth allowedPermissions={[PermissionsEnum.create_sport_centers]} />,
      children: [
        {
          path: 'create-object',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <CreateSportCenter activeStep={0} />
            },
            {
              path: ':sportCenterId',
              element: <CreateSportCenter activeStep={0} />
            },
            {
              path: ':sportCenterId/images',
              element: <CreateSportCenter activeStep={1} />
            },
            {
              path: ':sportCenterId/court',
              element: <CreateSportCenter activeStep={2} />
            },
            {
              path: ':sportCenterId/pricelist',
              element: <CreateSportCenter activeStep={3} />
            }
          ]
        }
      ]
    },

    {
      path: 'objects',
      element: <RequireAuth allowedPermissions={[PermissionsEnum.read_sport_centers]} />,
      children: [
        { path: '/objects', element: <SportCenters /> },
        {
          path: 'object/:objectId',
          element: <SportCenterOverviewLayout />,
          children: [
            {
              path: '',
              element: <SportCenterOverview />
            },
            {
              path: 'object-analytics',
              element: <SportCenterOverviewAnalitics />
            },
            {
              path: 'object-finance',
              element: <SportCenterOverviewFinance />
            }
          ]
        }
      ]
    },

    {
      path: 'users',
      element: <Outlet />,
      children: [
        {
          path: '/users',
          element: <RequireAuth allowedPermissions={[PermissionsEnum.read_users]} />,
          children: [{ path: '', element: <UsersPage /> }]
        },
        {
          path: 'app-users',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <AppUsersPage />
            },
            {
              path: ':userId',
              element: <SingleUserPage />
            }
          ]
        },
        {
          path: 'owners',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <OwnersPage />
            }
          ]
        },
        {
          path: 'managers',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <ManagersPage />
            }
          ]
        },
        {
          path: 'superusers',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <SuperUsersPage />
            },
            {
              path: 'superuser/:superUserId',
              element: <SuperUserOverview />
            }
          ]
        },
        {
          path: 'business',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <ComingSoonPage imageURL={bussinessUsersImage} />
              // <BusinessUsersPage />
            }
          ]
        },
        {
          path: 'business/create-user',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <AppUsersPage />
            }
          ]
        }
      ]
    },

    {
      path: 'cooperation',
      element: <Outlet />,
      children: [
        {
          path: 'employees',
          element: <RequireAuth allowedPermissions={[PermissionsEnum.read_employees]} />,
          children: [
            {
              path: '',
              element: <EmployeesPage />
            }
          ]
        },
        {
          path: 'external-employees',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <div>spoljni zaposleni</div>
            }
          ]
        },
        {
          path: 'part-time-employees',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <div>honorarni zaposleni</div>
            }
          ]
        },
        {
          path: 'sponsorships',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <div>sponzorstva</div>
            }
          ]
        },
        {
          path: 'employees/create',
          element: <CreateEmployee />
        },
        {
          path: 'employees/:id',
          element: <SingleEmployee />
        }
      ]
    },

    {
      path: 'analytics',
      element: <Outlet />,
      children: [
        {
          path: '/analytics',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <AnalyticsPage />
            }
          ]
        },
        {
          path: 'object',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <SportCentersAnalytics />
            }
          ]
        },
        {
          path: 'users',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <UserAnalyticsPage />
            }
          ]
        }
      ]
    },
    {
      path: 'finance',
      element: <Outlet />,
      children: [
        {
          path: '/finance',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <FinancesPage />
            }
          ]
        },
        {
          path: 'expenses',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <div>rashodi</div>
            }
          ]
        },
        {
          path: 'income',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <div>income</div>
            }
          ]
        },
        {
          path: 'reports',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <div>izvestaji</div>
            }
          ]
        },
        {
          path: 'invoices',
          element: <RequireAuth allowedPermissions={[]} />,
          children: [
            {
              path: '',
              element: <div>fakture</div>
            }
          ]
        }
      ]
    },

    {
      path: 'notifications',
      element: <RequireAuth allowedPermissions={[]} />,
      children: [
        {
          path: '',
          element: <ComingSoonPage imageURL={loginImage} />
        }
      ]
    },
    {
      path: 'settings',
      element: <RequireAuth allowedPermissions={[]} />,
      children: [
        {
          path: '',
          element: <SettingsPage />
        },

        {
          path: 'sports',
          element: <Outlet />,
          children: [
            { path: '', element: <SportSettingsPage /> },
            { path: 'create-sport', element: <CreateSportPage /> },
            { path: 'edit-sport/:sportId', element: <CreateSportPage /> }
          ]
        },
        {
          path: 'change-password',
          element: <ChangePasswordPage />
        },
        {
          path: 'access',
          element: <Outlet />,
          children: [
            { path: '', element: <RoleSettingsPage /> },
            { path: 'create-role', element: <CreateRolePage /> },
            { path: 'edit-role/:roleId', element: <CreateRolePage /> }
          ]
        },
        {
          path: 'appendices',
          element: <div>appendices</div>
        },
        {
          path: 'discounts',
          element: <div>discounts</div>
        },
        {
          path: 'language',
          element: <LanguageSettingsPage />
        }
      ]
    }
  ]
};
