// hooks
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
// components
import { Box, Stack, Typography } from '@mui/material';
import { Divider, Grid } from '@mui/material';
import AnalyticsCard from '@components/AnalyticsCard/AnalyticsCard';
import AreaChartSM from '@components/Charts/AreaChart/AreaChart';
import CardWrapper from '@containers/CardWrapper/CardWrapper';
import { TableLayout } from '@layout/TableLayout/TableLayout';
import CustomDatePicker from '@components/CustomDatePicker/CustomDatePicker';
// type
import { DateView } from '@mui/x-date-pickers';
import { AxiosResponse } from 'axios';
import { MRT_ColumnDef } from 'material-react-table';
import { ObjectAppointmentsFinanceType } from '@interfaces/finance/finance';
// recoil
import { popupAtom } from '@atoms/popupAtom';
//api
import {
  useGetObjectsAppointmentFinance,
  useGetObjectsTotalRevenueInPeriod,
  useGetObjectsTotalRevenue
} from '@api/queries/finance/finance';
// icons
import { ClockIcon, HouseIcon, MobileIcon } from '@components/icons/icons';
// helpers
import dayjs, { Dayjs } from 'dayjs';

const AdminFinancePage = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState({
    datetime_from: dayjs().startOf('day'),
    datetime_to: dayjs().endOf('day')
  });
  const [viewTypeArea, setViewTypeArea] = useState<DateView>('day');

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 5
  });

  const params = useParams();
  const objectId = params.sportCenterId ? +params.sportCenterId : 0;

  const setPopup = useSetRecoilState(popupAtom);

  const handleDateChange = (datetime_from: Dayjs, datetime_to: Dayjs) => {
    setDate({ datetime_from, datetime_to });
  };

  const onError = (err: AxiosResponse | undefined) => {
    setPopup({
      open: true,
      title: err?.data.message ?? 'Error',
      content: '',
      variant: 'error'
    });
  };

  const { data: totalRevenue } = useGetObjectsTotalRevenue(objectId, onError);
  const { data: totalRevenueInPeriod } = useGetObjectsTotalRevenueInPeriod(objectId, onError, {
    datetime_from: date.datetime_from.format('YYYY-MM-DD HH:mm'),
    datetime_to: date.datetime_to.format('YYYY-MM-DD HH:mm')
  });
  const { data: latestAppointments } = useGetObjectsAppointmentFinance(objectId, onError, {
    page: pagination.pageIndex,
    perPage: pagination.pageSize
  });

  const lastAppointmentsColums = useMemo<MRT_ColumnDef<ObjectAppointmentsFinanceType>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('id'),
        size: 100
      },
      {
        accessorKey: 'user.name',
        header: t('admin_analytics.appointment_carier'),
        size: 100
      },
      {
        accessorKey: 'channel',
        header: t('channel'),
        size: 100
      },
      {
        accessorKey: 'date',
        header: t('appointment'),
        Cell({ row }) {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body2">{row.original.date}</Typography>
              <Typography variant="body2">{row.original.time}</Typography>
            </Box>
          );
        },
        size: 100
      },
      {
        accessorKey: 'court_sport',
        header: t('field')
      },
      {
        accessorKey: 'price',
        header: t('price'),
        Cell({ row }) {
          return `${row.original.price} RSD`;
        },
        size: 100
      }
    ],
    [latestAppointments]
  );

  return (
    <Box m={2}>
      <Grid container spacing={3} mb={1}>
        <Grid item xs={12} sm={4}>
          <AnalyticsCard
            header=""
            content={`${totalRevenue?.total_revenue?.toLocaleString('sr-Latn-RS', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })} RSD`}
            percent={``}
            footerText={t('finances_overview.total_income_for_selected_object')}
            headerIcon={<HouseIcon fill="white" />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AnalyticsCard
            header=""
            content={`${totalRevenue?.appointments_from_app_revenue?.toLocaleString('sr-Latn-RS', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })} RSD`}
            percent={``}
            footerText={t('finances_overview.total_income_through_the_app')}
            headerIcon={<MobileIcon fill="white" />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AnalyticsCard
            header=""
            content={`${totalRevenue?.future_appointments_from_app_revenue?.toLocaleString(
              'sr-Latn-RS',
              {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }
            )} RSD`}
            percent={``}
            footerText={t('finances_overview.total_income_in_the_future')}
            headerIcon={<ClockIcon fill="white" />}
          />
        </Grid>
        <Grid item xs={12}>
          <CardWrapper
            sx={{ m: 0 }}
            title={
              <Stack>
                <Typography variant="h5">{t('income')}</Typography>
                <Typography variant="h3">
                  {totalRevenueInPeriod?.total_revenue?.toLocaleString('sr-Latn-RS', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}{' '}
                  RSD
                </Typography>
              </Stack>
            }
            subtitle={
              t('finances_overview.total_income') + ' ' + t('finances_overview.for_selected_period')
            }
            showMore={
              <CustomDatePicker
                viewType={viewTypeArea}
                setViewType={setViewTypeArea}
                value={dayjs().format('DD. MMM YYYY')}
                onChange={handleDateChange}
                maxDate={dayjs().format('DD. MMM YYYY')}
                withChangeView
              />
            }
            children={
              <>
                <Divider />
                <AreaChartSM
                  data={totalRevenueInPeriod?.appointments_count_by_hour}
                  tooltipValueText={t('appointments_count').toString()}
                />
              </>
            }
          />
        </Grid>
      </Grid>

      <CardWrapper sx={{ m: 0 }} title={t('finances_overview.last_transactions').toString()}>
        {latestAppointments?.data && (
          <TableLayout
            tableVariant="coaches"
            rows={latestAppointments?.data ?? []}
            columns={lastAppointmentsColums as MRT_ColumnDef<{}>[]}
            pagination={pagination}
            setPagination={setPagination}
            rowCount={latestAppointments?.meta?.total}
          />
        )}
      </CardWrapper>
    </Box>
  );
};

export default AdminFinancePage;
