// hooks
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
// components
import { Box, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import AreaChartSM from '@components/Charts/AreaChart/AreaChart';
import CardWrapper from '@containers/CardWrapper/CardWrapper';
import { TableLayout } from '@layout/TableLayout/TableLayout';
import CustomDatePicker from '@components/CustomDatePicker/CustomDatePicker';
// type
import { DateView } from '@mui/x-date-pickers';
import { AxiosResponse } from 'axios';
import { MRT_ColumnDef } from 'material-react-table';
import {
  ObjectAppointmentsFinanceType,
  ObjectBestRecurringAppontmentsType
} from '@interfaces/finance/finance';
// recoil
import { popupAtom } from '@atoms/popupAtom';
//api
import {
  useGetObjectsAppointmentFinance,
  useGetObjectsSportsRevenue,
  useGetObjectsTotalRevenueInPeriod,
  useGetObjectsBestRecurringAppointmentFinance
} from '@api/queries/finance/finance';
// helpers
import dayjs, { Dayjs } from 'dayjs';

const PermanentAppointmentsFinancesPage = () => {
  const { t } = useTranslation();

  const [date, setDate] = useState({
    datetime_from: dayjs().startOf('day'),
    datetime_to: dayjs().endOf('day')
  });
  const [viewTypeArea, setViewTypeArea] = useState<DateView>('day');

  const [sportPeriod, setSportPeriod] = useState<DateView>('month');

  const [sportPagination, setSportPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });

  const [bestAppPagination, setBestAppPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });

  const params = useParams();
  const objectId = params.sportCenterId ? +params.sportCenterId : 0;

  const setPopup = useSetRecoilState(popupAtom);

  const handleDateChange = (datetime_from: Dayjs, datetime_to: Dayjs) => {
    setDate({ datetime_from, datetime_to });
  };

  const onError = (err: AxiosResponse | undefined) => {
    setPopup({
      open: true,
      title: err?.data.message ?? 'Error',
      content: '',
      variant: 'error'
    });
  };

  const { data: totalRevenueInPeriod } = useGetObjectsTotalRevenueInPeriod(objectId, onError, {
    datetime_from: date.datetime_from.format('YYYY-MM-DD HH:mm'),
    datetime_to: date.datetime_to.format('YYYY-MM-DD HH:mm'),
    recurringAppointments: 1
  });
  const { data: latestAppointments } = useGetObjectsAppointmentFinance(objectId, onError, {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    recurringAppointments: 1
  });

  const { data: sportIncome } = useGetObjectsSportsRevenue(objectId, onError, {
    datetime_from: dayjs().startOf(sportPeriod).format('YYYY-MM-DD HH:mm'),
    datetime_to: dayjs().endOf(sportPeriod).format('YYYY-MM-DD HH:mm'),
    page: sportPagination.pageIndex + 1,
    perPage: sportPagination.pageSize,
    recurringAppointments: 1
  });

  const { data: bestAppointments } = useGetObjectsBestRecurringAppointmentFinance(
    objectId,
    onError,
    {
      page: bestAppPagination.pageIndex + 1,
      perPage: bestAppPagination.pageSize
    }
  );

  const sportIncomeColumns = useMemo<
    MRT_ColumnDef<{ time: string; [sport: string]: number | string }>[]
  >(() => {
    if (sportIncome?.data?.[0]) {
      return Object.keys(sportIncome?.data?.[0]).map(key => ({
        accessorKey: key,
        header: key
      }));
    }

    return [];
  }, [sportIncome]);

  const lastAppointmentsColumns = useMemo<MRT_ColumnDef<ObjectAppointmentsFinanceType>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('id'),
        size: 10
      },
      {
        accessorKey: 'user.name',
        header: t('admin_analytics.appointment_carier')
      },

      {
        accessorKey: 'date',
        header: t('appointment'),
        Cell({ row }) {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body2">{row.original.date}</Typography>
              <Typography variant="body2">{row.original.time}</Typography>
            </Box>
          );
        }
      },
      {
        accessorKey: 'court_sport',
        header: t('field')
      },
      {
        accessorKey: 'price',
        header: t('price'),
        Cell({ row }) {
          return `${row.original.price} RSD`;
        }
      }
    ],
    [latestAppointments]
  );

  const bestAppointmentsColumns = useMemo<MRT_ColumnDef<ObjectBestRecurringAppontmentsType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('calendar.permanentAppointment.name')
      },
      {
        accessorKey: 'carrier',
        header: t('admin_analytics.appointment_carier')
      },

      {
        accessorKey: 'appointment',
        header: t('appointment')
      },
      {
        accessorKey: 'court_sport',
        header: t('field')
      },
      {
        accessorKey: 'total_revenue',
        header: t('total'),
        Cell({ row }) {
          return `${row.original.total_revenue} RSD`;
        }
      }
    ],
    [bestAppointments]
  );

  return (
    <Box m={2} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <CardWrapper
        sx={{ m: 0 }}
        title={
          <Stack>
            <Typography variant="h5" fontWeight={'bold'}>
              {t('finance.recurring_appointments_income')}
            </Typography>
            <Typography variant="h3">
              {totalRevenueInPeriod?.total_revenue?.toLocaleString('sr-Latn-RS', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}{' '}
              RSD
            </Typography>
          </Stack>
        }
        subtitle={
          t('finances_overview.total_income') + ' ' + t('finances_overview.for_selected_period')
        }
        showMore={
          <CustomDatePicker
            viewType={viewTypeArea}
            value={dayjs().format('DD. MMM YYYY')}
            onChange={handleDateChange}
            maxDate={dayjs().format('DD. MMM YYYY')}
            withChangeView
            setViewType={setViewTypeArea}
          />
        }
      >
        <AreaChartSM
          data={totalRevenueInPeriod?.appointments_count_by_hour}
          tooltipValueText={t('appointments_count').toString()}
        />
      </CardWrapper>

      <CardWrapper sx={{ m: 0 }} title={t('finance.recurring_appointments_income').toString()}>
        {latestAppointments?.data && (
          <TableLayout
            tableVariant="coaches"
            rows={latestAppointments?.data ?? []}
            columns={lastAppointmentsColumns as MRT_ColumnDef<{}>[]}
            pagination={pagination}
            setPagination={setPagination}
            rowCount={latestAppointments?.meta?.total}
          />
        )}
      </CardWrapper>

      <CardWrapper
        sx={{ m: 0 }}
        title={t('finance.recurring_appointments_sport_income').toString()}
        showMore={
          <FormControl>
            <Select
              defaultValue={'month'}
              value={sportPeriod}
              onChange={e => {
                setSportPeriod(e?.target?.value as DateView);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                }
              }}
              sx={{
                borderRadius: '10px',
                padding: '0rem 1rem'
              }}
              size="small"
            >
              <MenuItem value="day">{t('day')}</MenuItem>
              <MenuItem value="week">{t('week')}</MenuItem>
              <MenuItem value="month">{t('month')}</MenuItem>
              <MenuItem value="year">{t('year')}</MenuItem>
            </Select>
          </FormControl>
        }
      >
        {sportIncome && (
          <TableLayout
            tableVariant="coaches"
            rows={sportIncome?.data ?? []}
            columns={sportIncomeColumns as MRT_ColumnDef<{}>[]}
            pagination={sportPagination}
            setPagination={setSportPagination}
            rowCount={sportIncome?.meta?.total}
          />
        )}
      </CardWrapper>

      <CardWrapper sx={{ m: 0 }} title={t('finance.best_recurring_appointments').toString()}>
        {bestAppointments?.data && (
          <TableLayout
            tableVariant="coaches"
            rows={bestAppointments?.data ?? []}
            columns={bestAppointmentsColumns as MRT_ColumnDef<{}>[]}
            pagination={bestAppPagination}
            setPagination={setBestAppPagination}
            rowCount={bestAppointments?.meta?.total}
          />
        )}
      </CardWrapper>
    </Box>
  );
};

export default PermanentAppointmentsFinancesPage;
